export default {
  init() {
    this.lvmhHouseId = $(`#template_house option`)
      .filter(function () {
        return $(this).text() === 'LVMH';
      })
      .attr('value');

    this.refreshTemplateList();
    $('#template_house').on('change', () => this.refreshTemplateList());
  },

  refreshTemplateList() {
    const house = $('#template_house').val();
    $(`#active_template option`).addClass('d-none');

    const $houseChannelOptions = $(`#active_template option[data-template-house='${house}']`);

    $houseChannelOptions.removeClass('d-none');

    const houseChannels = $houseChannelOptions
      .map(function () {
        return $(this).attr('data-template-channel');
      })
      .toArray()
      .reduce((obj, channel) => ({ ...obj, [channel]: true }), {});

    $(`#active_template option[data-template-house='${this.lvmhHouseId}']`)
      .filter(function () {
        const channel = $(this).attr('data-template-channel');
        return channel !== '' && houseChannels[channel] === undefined;
      })
      .removeClass('d-none');

    const firstAvailableTemplate = $('#active_template option:not(.d-none):first').val();
    $('#active_template').val(firstAvailableTemplate);
  },
};
