const selectizeConfig = {
  plugins: ['auto_position'],
  persist: true,
  render: {
    option: function (data) {
      return `<div data-value="${data.value}" data-short-value="${data.shortValue}" class='option'>${data.text}</div>`;
    },
    item: function (data) {
      return `<div data-value="${data.value}" data-short-value="${data.shortValue}" class='item'>${data.text}</div>`;
    },
  },
  onInitialize: function () {
    const item = this;

    document.addEventListener(
      'open_selectize',
      e => {
        if (item.eventNS !== e.detail.selectize.eventNS) {
          item.close();
        }
      },
      false
    );
  },
  onDropdownOpen: function () {
    const item = this;
    const customEvent = new CustomEvent('open_selectize', {
      bubbles: true,
      detail: {
        selectize: item,
      },
    });
    item.$control_input[0].dispatchEvent(customEvent);
  },

  onChange: function () {
    const item = this;
    const isNoneSelected = item['$control'][0].innerText === 'None selected';

    if (!isNoneSelected) {
      item['$input'][0].classList.remove('not-valid');
    }
  },
};

export default selectizeConfig;
