export default {
  init() {
    this.initHandlers();
  },

  initHandlers() {
    this.initDeleteRowButtons();
    if ($('#j-email-table .j-email-row').length === 1) {
      $('#j-email-table .j-email-row input').prop('required', false);
    }

    $('#j-add-email').on('click', e => {
      e.preventDefault();
      const $rowCount = $('#j-email-table .j-email-row').length;
      const $lastRow = $('#j-email-table').find('.j-email-row:last');
      const $newRow = $lastRow.clone();

      if ($rowCount === 1) {
        $lastRow.find('input').prop('required', true);
        $newRow.find('input').prop('required', true);
      }

      $newRow.find('input').val('');
      $newRow.insertAfter($lastRow);
      this.initDeleteRowButtons();
    });
  },

  initDeleteRowButtons() {
    $('.j-delete-email-row-button')
      .off('click')
      .on('click', e => {
        e.preventDefault();
        const $target = $(e.target);
        const $rowCount = $('#j-email-table .j-email-row').length;
        const $closestRow = $target.closest('.j-email-row');

        if ($rowCount === 1) {
          $closestRow.find('input').val('');
          return;
        }

        if ($rowCount === 2) {
          $('.j-email-row').find('input').prop('required', false);
        }

        $closestRow.remove();
      });
  },
};
