import FullPageGradient from '../components/FullPageGradient';

export default {
  init() {
    this.gradient = new FullPageGradient();
    this.gradient.init();
    this.form = document.querySelector('.form-signin');
    this.notification = document.querySelector('.notify-text');
    this.setFormValidation();
  },

  resize() {
    this.gradient.resize();
  },

  validate(input) {
    if (input === '') {
      return "Password can't be empty";
    }

    if (input.length < 8) {
      return 'Password should be at least 8 characters';
    }

    if (!input.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)) {
      return 'Password should contain at least one number, uppercase and lowercase letter';
    }

    return 'valid';
  },

  setFormValidation() {
    this.form.addEventListener('submit', event => {
      const val = this.form.querySelector('input').value;
      if (this.validate(val) !== 'valid') {
        this.addOnTheFlyCheck();
        this.toggleNotification(true);
        this.setNotification(this.validate(val));
        event.preventDefault();
      }
    });
  },

  addOnTheFlyCheck() {
    this.form.addEventListener('input', () => {
      const val = this.form.querySelector('input').value;
      if (this.validate(val) !== 'valid') {
        this.toggleNotification(true);
        this.setNotification(this.validate(val));
      } else {
        this.toggleNotification(false);
      }
    });
  },

  setNotification(text) {
    this.notification.textContent = text;
  },

  toggleNotification(visibility) {
    let state = 'none';
    if (visibility) {
      state = 'block';
    }

    this.notification.style.display = state;
  },
};
