import AlertNotification from '../components/AlertNotification';
import { addColumnValue } from '../utils/add_column_value';
import { forceInputUppercase } from '../utils/force_input_uppercase';
import { updateFileInput } from '../utils/update_file_button';

export default {
  init() {
    this.table = $('.j-table');
    this.initValidations();
    this.initHandlers();
    window.deleteRow = this.deleteRow.bind(this);
  },

  initHandlers() {
    this.initGlobalSubmitHandler();

    if ($('.column-list-form').length) {
      this.initLocalSubmitHandler();
    }
    $('.new_short_val').on('keyup', forceInputUppercase);

    updateFileInput();
  },

  initGlobalSubmitHandler() {
    $('#form-column-submit-btn').on('click', e => {
      e.preventDefault();
      const formEl = $(e.target).attr('form');
      const $form = $(`#${formEl}`);
      const isValid = $form[0].checkValidity();

      if (!$form.hasClass('was-validated')) {
        $form.addClass('was-validated');
      }

      if (isValid) {
        const shortValues = $form
          .find('.new_short_val')
          .map((i, el) => el.value)
          .get();

        const duplicates = shortValues.filter((item, index) => shortValues.indexOf(item) !== index);
        if (duplicates.length) {
          this.showError('Duplicate short values are not allowed: ' + duplicates.join(', '));
          return;
        }

        $form.submit();
      } else {
        $form[0].reportValidity();
      }
    });
  },

  initLocalSubmitHandler() {
    $('#add_new_col').on('click', function (e) {
      const formEl = $(e.target).attr('form');
      const $form = $(`#${formEl}`);

      if (!$form.hasClass('was-validated')) {
        $form.addClass('was-validated');
      }
    });
  },

  deleteRow(btn) {
    const row = btn.parentNode.parentNode;
    row.parentNode.removeChild(row);

    if (this.table.find('tbody tr').length === 0) {
      $('#header_tr').addClass('d-none');
    }
  },

  showError(text) {
    AlertNotification.AppendHtml('error', text, this.table.closest('.card'), true);
  },

  removeError() {
    AlertNotification.RemoveHtml(this.table.closest('.card'), true);
  },

  initValidations() {
    if ($('.column-list-form').length) {
      this.initValidationLocal();
    }
  },

  initValidationLocal() {
    $('.j-form-column-local').on('submit', e => {
      const $form = $(e.target);
      const isValid = $form[0].checkValidity();

      if (isValid) {
        const ln = this.table.find('tbody tr').length;
        if (ln === 0) {
          $('#header_tr').removeClass('d-none');
        }
        addColumnValue(this.table.find('tbody'), ln);
      }

      e.preventDefault();
      return false;
    });
  },
};
