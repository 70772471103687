import SelectBuilder from '../components/SelectBuilder';
import Placement from '../components/Placement';
import GenerateResult from '../components/GenerateResult';
import DimensionLinking from '../components/DimensionLinking';
import DuplicateRow from '../components/DuplicateRow';
import NewRow from '../components/NewRow';
import RemoveRow from '../components/RemoveRow';
import EditableFieldName from '../components/EditableFieldName';
import tableNotification from '../components/tableNotification';
import selectizeConfig from '../utils/selectize_config';

export default {
  timeout: 0,
  dataToSend: {},
  isNeedSend: false,
  isInputValid: false,
  isSelectValid: false,
  isRowValid: false,
  excludedSelectValues: '__manual',
  savedTimeoutCampaign: 0,
  savedTimeoutPlacement: 0,
  placementContainers: [],
  waitRowForId: {},
  init() {
    if (!$('.j-campaign-placement-editable-container').hasClass('template-readonly')) {
      this.table = $('.j-campaign-placement-editable-container');
      this.builderId = $('.j-campaign-placement-editable-container').attr('data-builder-id');
      this.PlacementsWrapper = $('.placements-wrapper');
      this.campaignTable = $('.j-campaign-table');
      this.builderCampaign = new SelectBuilder(this.campaignTable, 'campaign');
      this.generateResultCampaign = new GenerateResult(this.campaignTable);
      this.removeRowClass = new RemoveRow();
      const utmTable = $('.j-utm-table');

      this.dimensionLinking = new DimensionLinking(this.campaignTable, utmTable);

      const callBackAtTableRowAddition = () => {
        this.dimensionLinking.synchronizeAllElements();
        this.removeRowClass.computeRemoveButtonDisableStatus();
        this.initDatePickers();
      };

      this.builderCampaign.init();

      if (this.PlacementsWrapper) {
        const placementContainers = $('.j-placement-container');

        placementContainers.each((index, item) => {
          const placementContainer = $(item);
          const placementTable = placementContainer.find('.j-placement-table');
          this.placementContainers.push({
            placementTable: placementTable,
            id: placementContainer.attr('data-container-id'),
            builderPlacement: new SelectBuilder(placementTable, 'placement'),
            placement: new Placement(placementContainer),
            generateResultPlacement: new GenerateResult(placementTable),
            duplicateRow: new DuplicateRow(placementTable, callBackAtTableRowAddition),
          });
        });

        this.placementContainers.map(item => {
          item.builderPlacement.init();
          item.generateResultPlacement.init();
          item.placement.init();
          item.duplicateRow.init();

          this.initTableHandlers(item.placementTable);
        });
      }

      if (utmTable.length) {
        this.builderUtm = new SelectBuilder(utmTable, 'utm');
        this.utmDuplicateRow = new DuplicateRow(utmTable);
        this.utmGenerateResult = new GenerateResult(utmTable, () => {
          this.generateResultCampaign.clickHandlerFunction();
          const campaignName = this.generateResultCampaign.generatedArrayResult[0].text;

          let adSets = [];

          if (this.placementContainers[0]) {
            this.placementContainers[0].generateResultPlacement.clickHandlerFunction();
            adSets = this.placementContainers[0].generateResultPlacement.generatedArrayResult.map(r => r.text);
          }

          let ads = [];

          if (this.placementContainers[1]) {
            this.placementContainers[1].generateResultPlacement.clickHandlerFunction();
            ads = this.placementContainers[1].generateResultPlacement.generatedArrayResult.map(r => r.text);
          }

          return { campaignName, adSets, ads };
        });
        this.builderUtm.init();
        this.utmDuplicateRow.init();
        this.utmGenerateResult.init();
      }

      this.removeRowClass.init();
      this.generateResultCampaign.init();
      this.editableNewField = new EditableFieldName();
      this.editableNewField.init();

      this.dimensionLinking.init();

      this.newRowHandler = new NewRow(this.prepareDataTryToSend.bind(this), callBackAtTableRowAddition);
      this.newRowHandler.init();

      this.initHandlers();

      this.initDatePickers();
    } else {
      $('.j-export-work-button').attr('disabled', true);

      if ($('.j-campaign-placement-editable-container').hasClass('template-not-allow-to-change')) {
        this.initFunctionsForReadonlyBlocks();
      }
    }
  },

  initTableHandlers(placementTable) {
    placementTable.on('updateFromDependency duplicateItem removeItem', e => {
      const placementId = $(e.target).closest('.j-container').attr('data-container-id');

      this.placementContainers.map(item => {
        if (placementId === item.id) {
          if (item.generateResultPlacement.hasContent) {
            item.generateResultPlacement.clearResult();
          }
        }
      });
    });
  },

  initHandlers() {
    if (this.placementContainers) {
      this.placementContainers.map(item => {
        const placementContainer = $(`[data-container-id=${item.id}]`);
        if (!placementContainer.find('[data-row-id]').length) {
          this.newRowHandler.createNewRow(item.placement.createPlacementButton);
        }
      });
    }

    this.table.on('focusin', e => {
      const tr = $(e.target).closest('tr');
      if (!tr.hasClass('focus-in')) {
        tr.addClass('focus-in');
      } else {
        clearTimeout(this.timeout);
      }
    });

    this.table.on('selectorChanged', e => {
      const tr = $(e.target).closest('tr');
      const $form = $(e.target).closest('form');
      const tableName = $(e.target).closest('.editable-table').attr('data-block');
      const containerId = $(e.target).closest('.j-container').attr('data-container-id');
      this.timeout = setTimeout(() => {
        if (tr.hasClass('focus-in')) {
          tr.removeClass('focus-in');
        }

        if ($form && $form[0] && $form[0].hasOwnProperty('checkValidity')) {
          $form[0].checkValidity();
        }

        if (!$form.hasClass('was-validated')) {
          $form.addClass('was-validated');
        }

        if (tr.attr('data-row-id')) {
          this.prepareDataTryToSend(tr, tableName, containerId);
        } else {
          if (this.waitRowForId.hasOwnProperty([tr.attr('data-index')])) {
            this.waitRowForId[tr.attr('data-index')].push(() => {
              this.prepareDataTryToSend(tr, tableName, containerId);
            });
          } else {
            this.prepareDataTryToSend(tr, tableName, containerId);
          }
        }
      }, 100);
    });

    this.table.on('select input', e => {
      const tableName = $(e.target).closest('.editable-table').attr('data-block');
      if (tableName === 'campaign') {
        if (this.generateResultCampaign.hasContent) {
          this.generateResultCampaign.clearResult();
        }
      } else {
        const placementId = $(e.target).closest('.j-placement-container').attr('data-container-id');
        this.placementContainers.map(item => {
          if (placementId === item.id) {
            if (item.generateResultPlacement.hasContent) {
              item.generateResultPlacement.clearResult();
            }
          }
        });
      }
    });

    $('.j-export-work-button').on('click', () => this.onExportClick());
  },

  prepareDataTryToSend(tr, tableName, containerId) {
    this.prepareData(tr, tableName);
    if (this.isNeedSend) {
      this.sendRequest(tr, tableName, containerId);
    }
  },

  initFunctionsForReadonlyBlocks() {
    $('.delete-row.disabled, .j-duplicate-row.disabled').on('click', e => {
      const containerId = $(e.target).closest('.j-container').attr('data-container-id');

      tableNotification.showMessageByTime(
        containerId,
        'Sorry, this work was created by another user hence you are not able to make any modifications to it',
        'error',
        2000
      );
    });
  },

  initDatePickers() {
    $('[data-provide="datepickerrange"]:not(initialized)').each(function () {
      const $this = $(this);
      let initialDate = null;
      if ($this.attr('data-origin') !== '') {
        initialDate = $this.attr('data-origin');
      }
      flatpickr.l10ns.default.rangeSeparator = '_';
      flatpickr(this, {
        mode: 'range',
        minDate: 'today',
        allowInput: true,
        enableTime: true,
        showTime: false,
        defaultDate: initialDate,
        closeOnSelect: false,
        formatDate: function (date) {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().replace(/^([0-9])$/, '0$1');
          const genericDateWithoutDay = `${year}${month}`;
          return genericDateWithoutDay;
        },
        onClose: function (arraySelectedDates, datastr) {
          if (arraySelectedDates.length !== 0) {
            let originDatesString = '';
            arraySelectedDates.map((date, index) => {
              const year = date.getFullYear();
              const month = (date.getMonth() + 1).toString().replace(/^([0-9])$/, '0$1');
              const day = date
                .getDate()
                .toString()
                .replace(/^([0-9])$/, '0$1');
              const genericDateWithDay = `${year}-${month}-${day}`;
              if (index === 1) {
                originDatesString += '_';
                originDatesString += genericDateWithDay;
                $this.attr('data-origin', originDatesString);
              } else {
                originDatesString += genericDateWithDay;
              }
            });
            $this.trigger('dateInputChanged');
            $this.trigger('selectorChanged');
            $this.trigger('input');
            $this.val(datastr);
          }
        },
        onCancel: function (date, datastr, instance) {
          const str = $this.attr('data-prev');
          if (str.indexOf('_') !== -1) {
            const separatorIndex = str.indexOf('_');
            const nonFormatedStartDate = str.substring(0, separatorIndex);
            const nonFormatedEndDate = str.substring(separatorIndex + 1, str.length);
            const startDateFull = new Date(nonFormatedStartDate);
            const startYear = startDateFull.getFullYear();
            const startMonth = (startDateFull.getMonth() + 1).toString().replace(/^([0-9])$/, '0$1');
            const endDateFull = new Date(nonFormatedEndDate);
            const endYear = endDateFull.getFullYear();
            const endMonth = (endDateFull.getMonth() + 1).toString().replace(/^([0-9])$/, '0$1');
            $this.val(`${startYear}${startMonth}_${endYear}${endMonth}`);
            instance.setDate(str, false, instance.config.dateFormat);
          }
        },
      });
      $this.addClass('initialized');
    });

    $('[data-provide="datepicker"]:not(initialized)').each(function () {
      const $this = $(this);
      let initialDate = null;
      if ($this.attr('data-origin') !== '') {
        initialDate = $this.attr('data-origin');
      }
      flatpickr(this, {
        mode: 'single',
        minDate: 'today',
        allowInput: true,
        enableTime: true,
        showTime: false,
        closeOnSelect: false,
        defaultDate: initialDate,
        dateFormat: 'd-m-y',
        onClose: function (arraySelectedDates, datestr) {
          if (arraySelectedDates.length !== 0) {
            $this.attr('data-origin', datestr);
            $this.trigger('dateInputChanged');
            $this.trigger('selectorChanged');
            $this.trigger('input');
            $this.val(datestr);
          }
        },
        onCancel: function (date, datestr, instance) {
          const str = $this.attr('data-prev');
          if (str.indexOf('/') !== -1) {
            $this.val(str);
            instance.setDate(str, false, instance.config.dateFormat);
          }
        },
      });
      $this.addClass('initialized');
    });
  },

  prepareData(tr, tableName) {
    this.dataToSend = {
      row_data: {
        unit_position: '',
        action: '',
        builder_id: '',
        placement_id: '',
        utm_row_id: '',
        inputs: [],
        selects: [],
      },
    };

    this.generateAction(tr, tableName);
    this.collectData(tr);
  },

  generateAction(tr, tableName) {
    switch (tableName) {
      case 'campaign':
        this.dataToSend.row_data.action = 'update';
        this.dataToSend.row_data.builder_id = tr.attr('data-row-id');
        break;

      case 'placement':
        this.placementContainers.map(item => {
          const placementId = tr.closest('.j-placement-container').attr('data-container-id');
          if (item.id === placementId) {
            if (tr.attr('data-row-id')) {
              this.dataToSend.row_data.action = 'update';
              this.dataToSend.row_data.placement_id = tr.attr('data-row-id');
              // this.dataToSend.row_data.placement_row_id = tr.attr('data-row-id');
            } else {
              this.dataToSend.row_data.action = 'save';
              this.dataToSend.row_data.builder_id = item.placement.builderId;
              this.waitRowForId[tr.attr('data-index')] = [];
            }
            this.dataToSend.row_data.unit_position = tr.closest('.j-placement-container').attr('data-container-id');
          }
        });
        break;

      case 'utm':
        this.dataToSend.row_data.builder_id = tr.attr('data-builder-id');
        this.dataToSend.row_data.utm_row_id = tr.attr('data-row-id');
        break;

      default:
        break;
    }
  },

  collectData(tr) {
    tr.find('input').each((index, item) => {
      const $item = $(item);

      if (!$item.is(':disabled') && $item.hasClass('form-control')) {
        let itemValue = $item.val();
        if (item.hasAttribute('data-origin')) {
          itemValue = $item.attr('data-origin');
        }

        if (itemValue !== $item.attr('data-prev')) {
          $item.attr('data-prev', itemValue);
          this.isNeedSend = true;

          if (this.isItemToPush($item, 'input')) {
            this.dataToSend.row_data.inputs.push({
              column_template_id: $item.attr('data-template-column-id'),
              new_value: itemValue,
            });
          }
        }
      }
    });

    tr.find('select').each((_, item) => {
      const $item = $(item);
      const itemValue = $item.val() ? $item.val().trim() : null;

      if (
        !$item.is(':disabled') ||
        ($item.is(':disabled') && $item.find('option').attr('data-short-value') === '__empty')
      ) {
        if (itemValue !== $item.attr('data-prev')) {
          $item.attr('data-prev', itemValue);
          const newValue = itemValue && itemValue !== '' ? itemValue : null;

          this.isNeedSend = true;

          if (this.isItemToPush($item, 'select')) {
            this.dataToSend.row_data.selects.push({
              column_template_id: $item.attr('data-template-column-id'),
              new_value: newValue,
            });
          }
        }
      }
    });
  },

  isItemToPush($item, type) {
    if (type === 'select') {
      const $selectedOption = $item.find('option:selected');
      const selectized = $item.hasClass('selectized');
      const shortValue = selectized ? $selectedOption.text().trim() : $selectedOption.data('short-value');

      return shortValue === null || (!!shortValue && this.excludedSelectValues.indexOf(shortValue) === -1);
    } else {
      return !($item.attr('data-disabled') && $item.attr('data-disabled') === 'true');
    }
  },

  sendRequest(tr, tableName, containerId) {
    $(`[data-container-id="${containerId}"]`).addClass('disabled');
    this.startLoading(containerId);
    $.ajax({
      method: 'POST',
      data: JSON.stringify(this.dataToSend),
      url: '/builder/' + tableName + '/save_update',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    })
      .done(data => {
        this.successRequest(tr, tableName, containerId, data);
        this.finishLoadingWithSuccess(containerId);
      })
      .fail(() => {
        this.finishLoadingWithError(containerId);
      })
      .always(() => {
        $(`[data-container-id="${containerId}"]`).removeClass('disabled');

        $('select').each((index, item) => {
          const isDisabled = $(item).prop('disabled');

          if (!isDisabled && !item.selectize) {
            $(item).removeClass('form-control').selectize(selectizeConfig);
            $(item).addClass('form-control');
            $(item).parents('.table-responsive').addClass('has-selectize');
          }
        });
      });
  },

  successRequest(tr, tableName, containerId, data) {
    // if (tableName === 'campaign') {
    // if (this.containerPlacement && this.containerPlacement.hasClass('hide')) {
    //   this.placement.tryCreateNewRow(this.placement.createBtn);
    //   this.showPlacementTable();
    // }
    // }

    if (tableName === 'placement' && !tr.attr('data-row-id')) {
      tr.attr('data-row-id', data.placement_id);
    }

    if (tableName === 'utm') {
      tr.attr('data-row-id', data.utmRowId);
    }

    this.isNeedSend = false;

    if (this.waitRowForId.hasOwnProperty([tr.attr('data-index')])) {
      this.waitRowForId[tr.attr('data-index')] = this.waitRowForId[tr.attr('data-index')].filter(func => {
        func();
        return false;
      });
      delete this.waitRowForId[tr.attr('data-index')];
    }
  },

  startLoading(containerId) {
    tableNotification.showMessage(containerId, 'Saving...', 'notification');
  },

  finishLoadingWithSuccess(containerId) {
    tableNotification.showMessageByTime(containerId, 'Saved', 'success', 2000);
  },

  finishLoadingWithError(containerId) {
    tableNotification.showMessageByTime(containerId, 'Error while saving', 'error', 2000);
  },

  onExportClick() {
    const blocks = [];

    this.generateResultCampaign.clickHandlerFunction();
    blocks.push(this.generateResultCampaign.generatedArrayResult.map(r => r.text));

    for (const placement of this.placementContainers) {
      placement.generateResultPlacement.clickHandlerFunction();
      blocks.push(placement.generateResultPlacement.generatedArrayResult.map(r => r.text));
    }

    if (this.utmGenerateResult) {
      this.utmGenerateResult.clickHandlerFunction();
      blocks.push(this.utmGenerateResult.generatedArrayResult.map(r => r.text));
    }

    $.ajax({
      method: 'POST',
      data: JSON.stringify({ blocks }),
      url: '/work/' + this.builderId + '/export',
      contentType: 'application/json; charset=utf-8',
      xhrFields: { responseType: 'blob' },
    })
      .done((blob, status, xhr) => {
        var filename = xhr.getResponseHeader('Content-Disposition').split('filename=')[1];
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .fail((xhr, status, error) => {
        console.log(error);
      });
  },

  // showPlacementTable () {
  //   this.containerPlacement.removeClass('hide');
  // },
};
