import AlertNotification from './AlertNotification';

export default class EditableFieldName {
  init() {
    this.input = $('.j-editable-work-name');
    this.timeoutSendData = '';
    this.initHandlers();
  }

  initHandlers() {
    this.input.on('focusin', e => {
      const $target = $(e.target);
      if (!$target.closest('.j-editable-input-wrapper').hasClass('focus-in')) {
        $target.closest('.j-editable-input-wrapper').addClass('focus-in');
      }
    });

    this.input.on('focusout', e => {
      const $target = $(e.target);
      if ($target.closest('.j-editable-input-wrapper').hasClass('focus-in')) {
        setTimeout(() => {
          $target.closest('.j-editable-input-wrapper').removeClass('focus-in');
        }, 250);
      }

      this.timeoutSendData = setTimeout(() => {
        this.tryToSend(this.input);
      }, 200);
    });

    $('.j-editable-input-wrapper').on('click', e => {
      const $target = $(e.target);
      if ($target.hasClass('j-tick')) {
        this.tryToSend(this.input);
      } else if ($target.hasClass('j-cancel')) {
        clearTimeout(this.timeoutSendData);
        if (this.input.val() !== this.input.attr('data-prev')) {
          this.input.val(this.input.attr('date-prev'));
        }
        if (this.input.hasClass('not-valid')) {
          this.input.removeClass('not-valid');
        }
      } else if ($target.hasClass('j-edit-name-button')) {
        this.input.focus();
      }
    });
  }

  tryToSend(input) {
    if (input.val().trim() === '') {
      if (!input.hasClass('not-valid')) {
        input.addClass('not-valid');
        AlertNotification.AppendHtml(
          'error',
          'The field is empty, please type the name of the work',
          this.input.closest('.card'),
          true
        );
      }
    } else if (input.val() !== input.attr('data-prev')) {
      if (input.hasClass('not-valid')) {
        input.removeClass('not-valid');
        AlertNotification.RemoveHtml(this.input.closest('.card'), true);
      }
      this.dataToSend = {
        builder_id: input.attr('data-row-id'),
        work_name: input.val(),
      };
      this.sendRequest();
    }
  }

  sendRequest() {
    $.ajax({
      method: 'POST',
      data: JSON.stringify(this.dataToSend),
      url: '/builder/work_name/update',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    })
      .done(() => {
        this.successResponse();
      })
      .fail(() => {});
  }

  successResponse() {
    this.input.attr('date-prev', this.input.val());
  }
}
