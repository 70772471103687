/** @module AlertNotification */
export default {
  /** @property customElements {string} - class name for alert block */
  customAlertClass: 'alert-validation',

  /**
   * @param {string} label - bootstrap class
   * @param {string} text - error text
   * @param {jquery} selector - jquery object of the block
   * @param {boolean} position - before block (true), after block (false)
   */
  AppendHtml(label, text, selector, position) {
    const layout = `<div class="alert alert-${label} alert-dismissible fade show mb-3 mt-0 ${this.customAlertClass}">
                        ${text}
                        <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>`;

    if (!selector.parent().find('.alert-error').length) {
      if (position) {
        selector.before(layout);
      } else {
        selector.after(layout);
      }
    }
  },

  /**
   * @param {jquery} selector - jquery object of the block
   * @param {boolean} position - before block (true), after block (false)
   */
  RemoveHtml(selector, position) {
    if (position) {
      if (selector.prev(`.${this.customAlertClass}`).length) {
        selector.prev(`.${this.customAlertClass}`).remove();
      }
    } else {
      if (selector.next(`.${this.customAlertClass}`).length) {
        selector.next(`.${this.customAlertClass}`).remove();
      }
    }
  },
};
