export default {
  init() {
    this.initHandlers();
  },

  initHandlers() {
    $('#removePopup').on('show.bs.modal', function (event) {
      const button = $(event.relatedTarget);
      const modal = $(this);
      modal.find('[data-delete="true"]').attr('href', button.attr('data-href'));
      modal.find('.modal-title').text('You will delete this channel, are you sure?');
    });
  },
};
