import FullPageGradient from '../components/FullPageGradient';

export default {
  init() {
    this.gradient = new FullPageGradient();
    this.gradient.init();
  },

  resize() {
    this.gradient.resize();
  },
};
