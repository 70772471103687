export default class DimensionLinking {
  constructor(campaignTable, utmTable) {
    this.campaignTable = campaignTable;
    this.utmTable = utmTable;
  }

  init() {
    this.campaignTable
      .find('tr input, select')
      .filter(':not([disabled=true]):not([data-disabled=true])')
      .each((_, input) => {
        const $input = $(input);
        const columnId = $input.attr('data-column-id');
        const eventName = this.isDateInput($input) ? 'dateInputChanged' : 'change';

        $input.on(eventName, e => {
          this.updateAllFieldByColumnId(columnId, $(e.currentTarget));
        });
      });
  }

  synchronizeAllElements() {
    this.campaignTable
      .find('tr input, select')
      .filter(':not([disabled=true]):not([data-disabled=true])')
      .each((_, input) => {
        const $input = $(input);
        const columnId = $input.attr('data-column-id');
        this.updateAllFieldByColumnId(columnId, $input);
      });
  }

  updateAllFieldByColumnId(columnId, sourceElement) {
    const tableRowsToUpdates = {};
    const newValue = sourceElement.val();

    $(`tr input, select`)
      .filter(`[data-column-id=${columnId}]`)
      .filter((_, input) => newValue !== $(input).val())
      .each((_, input) => {
        const $input = $(input);
        if (this.isDateInput($input)) {
          $input.attr('data-origin', sourceElement.attr('data-origin'));
        }
        $input.val(newValue);

        const tr = $input.closest('tr');
        tableRowsToUpdates[tr.attr('data-index')] = {
          tr,
          data: { changedElement: input, changedDataId: input.dataset.columnId, value: input.value },
        };
      });

    Object.values(tableRowsToUpdates).forEach(({ tr, data }) => {
      const $table = tr.closest('table');
      $table.trigger('updateChanges', data);
    });
  }

  isDateInput(input) {
    return input.is('.input-datepicker,.input-datepickerrange');
  }
}
