export default {
  init() {
    this.initHandlers();
  },

  initHandlers() {
    const $form = $('.j-form-edit-dependencies');

    $form.on('submit', e => {
      e.preventDefault();
      $form.find("option:contains('__none_selected')").each((index, item) => {
        $(item).prop('selected', true);
        $(item).attr('selected', 'selected');
      });
      $form.off('submit');
      $form.submit();
    });
  },
};
