import { reIndexTableRows } from '../utils/re_index_template_unit_rows';

export default class Placement {
  constructor(container) {
    this.container = container;
    this.table = this.container.find('.j-placement-table');
    this.createPlacementButton = this.container.find('.j-new-row');
    this.builderId = $('.j-campaign-placement-editable-container').attr('data-builder-id');
  }

  init() {
    reIndexTableRows();
  }
}
