import { reIndexTableRows } from '../utils/re_index_template_unit_rows';
import { collectInputsAndAddListener } from '../utils/use_input_cleaning_pattern';
import { ADS_TABLE_NAME } from '../constants';

export default class NewRow {
  constructor(prepareDataTryToSend, callBackAtTableRowAddition = () => {}) {
    this.prepareDataTryToSend = prepareDataTryToSend;
    this.callBackAtTableRowAddition = callBackAtTableRowAddition;
  }

  init() {
    const containerHandler = $containers => {
      const trCountPerContainer = this.getTrCountPerContainers($containers);
      const maxTrCount = Math.max(...Object.values(trCountPerContainer));

      $containers.forEach(container => {
        const $container = $(container);
        const containerId = $container.attr('data-container-id');
        const $button = $container.find('.j-new-row');
        const numberOfTrToCreate = maxTrCount - trCountPerContainer[containerId] + 1;

        for (let index = 0; index < numberOfTrToCreate; index++) {
          this.createNewRow($button);
        }
      });

      $('.j-delete-row, .j-duplicate-row, .j-new-row, .j-generate-result, .j-export-work-button').removeClass(
        'disabled'
      );
    };

    $('.j-new-placement').on('click', event => {
      const isAdsTable = $(event.target).closest('.card').find('h3').text().trim().toLowerCase() === ADS_TABLE_NAME;
      const placement = $(event.target).closest('.j-placement-container');
      $('.j-delete-row, .j-duplicate-row, .j-new-row, .j-generate-result, .j-export-work-button').addClass('disabled');
      let $containers = [];

      if (isAdsTable) {
        $containers = [...placement, ...$('.j-utm-container')];
      } else {
        $containers = [...placement];
      }

      containerHandler($containers);
    });

    $('.j-new-utm').on('click', () => {
      const adsTitle = $('.j-placement-container h3').filter(
        (_, item) => $(item).text().trim().toLowerCase() === ADS_TABLE_NAME
      );
      const utm = $('.j-utm-container');
      $('.j-delete-row, .j-duplicate-row, .j-new-row, .j-generate-result, .j-export-work-button').addClass('disabled');
      let $containers = [];

      if (adsTitle) {
        const adsContainer = adsTitle.closest('.j-placement-container');
        $containers = [...adsContainer, ...utm];
      } else {
        $containers = [...utm];
      }

      containerHandler($containers);
    });
  }

  createNewRow(button) {
    const $container = button.closest('.j-container');
    const $table = $container.find('table');
    const url = button.attr('data-href');

    $.ajax({ method: 'GET', url })
      .then(data => {
        const tr = this.update($table, data);
        this.prepareDataTryToSend(tr, $table.attr('data-block'), $container.attr('data-container-id'));

        setTimeout(() => {
          collectInputsAndAddListener();
        }, 300);
      })
      .catch(err => {
        console.error(err);
      });
  }

  update(table, data) {
    const tr = $(data);
    table.find('tbody').append(tr);
    this.preparePlacementToLoadData(tr);
    this.callBackAtTableRowAddition();
    reIndexTableRows();

    return tr;
  }

  preparePlacementToLoadData(tr) {
    tr.find('.form-control').each((_, item) => {
      const $item = $(item);
      if ($item.attr('data-parent-id')) {
        $(`.j-campaign-table [data-column-id="${$item.attr('data-parent-id')}"]`).trigger('change', {
          action: 'add-new',
          tr: tr,
        });
      }

      if ($item.attr('data-mother-id')) {
        $(`.j-campaign-table [data-column-id="${$item.attr('data-mother-id')}"]`).trigger('change', {
          action: 'add-new',
          tr: tr,
        });
      }
    });
  }

  getTrCountPerContainers(containers) {
    return containers.reduce((counts, container) => {
      const $container = $(container);
      const containerId = $container.attr('data-container-id');
      const trCount = $container.find('tbody tr').length;

      return { ...counts, [containerId]: trCount };
    }, {});
  }
}
