import { collectInputsAndAddListener } from '../utils/use_input_cleaning_pattern';
import selectizeConfig from '../utils/selectize_config';

export default class SelectBuilder {
  constructor(el, blockName) {
    this.table = el;
    this.blockName = blockName;
    this.timeoutForSave = {};
  }

  init() {
    this.addHandlers();
    this.keepDataValue(this.table);
  }

  addHandlers() {
    this.table.on('change', (e, data) => {
      let objSend = {
        changedElement: e.target,
        changedDataId: e.target.dataset.columnId,
        value: e.target.value,
      };

      if (data) {
        objSend = Object.assign({}, objSend, data);
      }

      this.table.trigger('updateChanges', objSend);
    });

    this.table.on('updateChanges', (e, data) => {
      this.env = this.table;

      if ($(data.changedElement)[0].hasAttribute('data-disabled')) {
        this.toggleManualInput(data.changedElement);
      }

      if (this.blockName === 'campaign' || this.blockName === 'utm') {
        if (this.table.hasClass('j-placement-table')) {
          this.table.trigger('updateFromDependency');
        }
        $('.j-placement-table').each((index, item) => {
          if (
            $(item).find('[data-parent-id="' + data.changedDataId + '"]').length ||
            $(item).find('[data-mother-id="' + data.changedDataId + '"]').length
          ) {
            this.updateDependency($(item), data);
          }
        });
        this.updateDependency(this.table, data);
      } else {
        this.env = $(data.changedElement).closest('tr');
        this.updateDependency(this.env, data);
      }
    });

    this.table.on('click', '.close-manual i', e => {
      const $select = $(e.target).parent().siblings('select');
      $select
        .attr('data-disabled', 'false')
        .removeAttr('disabled')
        .val($select.find('option:first').val(''))
        .siblings('input.form-control')
        .attr('data-disabled', 'true')
        .attr('disabled', 'true')
        .val('');
      $select.trigger('change');
      $select.trigger('focusout');

      const isDisabled = $select.prop('disabled');
      // const hasEnoughOptions = $select.find('option').length > 9;

      if (!isDisabled && !$select.hasClass('selectized')) {
        $select.removeClass('form-control').selectize(selectizeConfig);
        $select.addClass('form-control');
        $select.parents('.table-responsive').addClass('has-selectize');
      }
    });
  }

  toggleManualInput(data) {
    const $select = $(data);
    let shortValue;

    if ($select.hasClass('selectized')) {
      shortValue = $select.siblings('div.selectize-control').find('.item').attr('data-short-value');
    } else {
      shortValue = $select.find('option:selected').attr('data-short-value');
    }

    if (shortValue && (shortValue === '__manual' || shortValue === '[Manual Input]')) {
      $select
        .attr('data-disabled', 'true')
        .attr('disabled', 'true')
        .siblings('input.form-control')
        .attr('data-disabled', 'false')
        .removeAttr('disabled');
    } else {
      $select
        .attr('data-disabled', 'false')
        .removeAttr('disabled')
        .siblings('input.form-control')
        .attr('data-disabled', 'true')
        .attr('disabled', 'true');
      // .val('');
    }
  }

  replaceArbitraryValues(item) {
    for (let i = 0; i < item.options.length; ++i) {
      this.replaceItemValue(item.options[i], ['__manual', '__empty'], ['[Manual Input]', '[None]']);
    }
  }

  replaceItemValue(item, values, replace) {
    if (values.indexOf(item.textContent) !== -1) {
      item.textContent = replace[values.indexOf(item.textContent)];
    }
  }

  updateDependency(env, data) {
    env.find('[data-parent-id="' + data.changedDataId + '"]').each((index, item) => {
      if (!!$(data.changedElement).val() && $(data.changedElement).val().trim() !== '') {
        $(item).attr('data-parent-value', data.value);
        this.tryRequest($(item).closest('tr'), item);
      } else {
        $(item).html('<option value></option>');
      }
    });

    env.find('[data-mother-id="' + data.changedDataId + '"]').each((index, item) => {
      if (!!$(data.changedElement).val() && $(data.changedElement).val().trim() !== '') {
        $(item).attr('data-mother-value', data.value);
        this.tryRequest($(item).closest('tr'), item);
      } else {
        $(item).html('<option value></option>');
      }
    });

    if (
      !env.find('[data-parent-id="' + data.changedDataId + '"]').length &&
      !env.find('[data-mother-id="' + data.changedDataId + '"]').length
    ) {
      $(data.changedElement).trigger('selectorChanged');
    }
  }

  keepDataValue(env) {
    env.find('select').each((index, item) => {
      this.replaceArbitraryValues(item);
      const valParent = env.find('[data-column-id="' + $(item).attr('data-parent-id') + '"]').val();

      if (valParent) {
        if (valParent.trim() !== '') {
          $(item).attr('data-parent-value', valParent);
        }
      }

      const valMother = env.find('[data-column-id="' + $(item).attr('data-mother-id') + '"]').val();

      if (valMother) {
        if (valMother.trim() !== '') {
          $(item).attr('data-mother-value', valMother);
        }
      }
    });
  }

  tryRequest(env, item) {
    const requestObj = {};
    if (item.hasAttribute('data-parent-id')) {
      if (typeof item.dataset.parentValue !== 'undefined') {
        if (item.dataset.parentValue.trim() !== '' && item.dataset.parentValue.trim() !== 'null') {
          requestObj.parent_value_id = item.dataset.parentValue;
          requestObj.column_id = item.dataset.columnId;
          requestObj.action = 'single';
          if (item.hasAttribute('data-mother-id')) {
            if (typeof item.dataset.motherValue !== 'undefined') {
              if (item.dataset.motherValue.trim() !== '' && item.dataset.motherValue.trim() !== 'null') {
                requestObj.mother_value_id = item.dataset.motherValue;
                requestObj.action = 'double';
                this.sendRequest(requestObj, item.dataset.columnId, env);
              }
            } else {
              this.sendRequest(requestObj, item.dataset.columnId, env);
            }
          }
        }
      } else {
        this.keepDataValue(env);
        const localItem = env.find(`[data-column-id="${$(item).attr('data-parent-id')}"]`);

        if (localItem.length) {
          this.tryRequest(env, localItem[0]);
        }
      }
    }
  }

  sendRequest(sendObj, columnId, env) {
    const $select = $(`[data-column-id="${columnId}"]`);

    $.ajax({
      method: 'POST',
      data: JSON.stringify(sendObj),
      url: '/builder/refresh_list',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    })
      .done(data => {
        const response = JSON.parse(data);
        let value = $select.val();
        if ($select.hasClass('selectized') && $select[0].selectize) {
          $select[0].selectize.updateOriginalInput();
          value = $select[0].selectize.getValue();
          $select[0].selectize.destroy();
        }

        this.updateValue(response, columnId, env);

        if (value) {
          $select.val(value);
        }

        if (!$select.hasClass('selectized')) {
          $select.removeClass('form-control').selectize(selectizeConfig);
          $select.addClass('form-control');
          $select.parents('.table-responsive').addClass('has-selectize');
        }
      })
      .fail(err => {
        console.error(err);
      });
  }

  updateValue(response, columnId, env) {
    const select = env.find('[data-column-id="' + columnId + '"]');
    select.each((index, selectItem) => {
      const $select = $(selectItem);
      let isOnly = false;

      let hasManual = false;
      $select.siblings('input').remove();
      $select.siblings('.close-manual').remove();
      if ($select.attr('data-disabled')) {
        $select.removeAttr('data-disabled');
      }

      if ($select.is(':disabled')) {
        $select.removeAttr('disabled');
      }

      if (response.length > 2) {
        let options = '';

        response.map(item => {
          if (item.option_short_value === '__manual') {
            hasManual = true;
            options += `<option value="${item.option_value}" data-short-value="${item.option_short_value}">[Manual Input]</option>`;
          } else if (item.option_short_value === '__none_selected') {
            options += `<option value="${item.option_value}" data-short-value="${item.option_short_value}">None selected</option>`;
          } else {
            options += `<option value="${item.option_value}" data-short-value="${item.option_short_value}">${item.option_text}</option>`;
          }
        });
        $select.html(options);
      } else if (response.length === 2) {
        let options = '';
        let hasNoneSelected = false;
        response.map(item => {
          if (item.option_short_value === '__manual') {
            hasManual = true;
            options += `<option value="${item.option_value}" data-short-value="${item.option_short_value}">[Manual Input]</option>`;
          } else if (item.option_short_value !== '__none_selected') {
            options += `<option value="${item.option_value}" data-short-value="${item.option_short_value}">${item.option_text}</option>`;
          } else if (item.option_short_value === '__none_selected') {
            hasNoneSelected = true;
          }
        });

        if (hasNoneSelected) {
          isOnly = true;
        }
        $select.html(options);

        if (isOnly) {
          $select.find('option').attr('selected', 'selected');
        }
      } else {
        if (response.length) {
          // Need to check
          const item = response[0];
          if (item.option_short_value === '__manual') {
            $select.html(
              `<option value="${item.option_value}" data-short-value="${item.option_short_value}" selected>[Manual Input]</option>`
            );
            hasManual = true;
            isOnly = true;
          } else if (item.option_short_value === '__empty') {
            $select.html(
              `<option value="${item.option_value}" data-short-value="${item.option_short_value}" selected>[None]</option>`
            );
            // $select.attr("disabled","disabled");
          } else if (item.option_short_value === '__none_selected') {
            $select.html(
              `<option value="${item.option_value}" data-short-value="${item.option_short_value}" selected>None selected</option>`
            );
          } else {
            $select.html(
              `<option value="${item.option_value}" data-short-value="${item.option_short_value}" selected>${item.option_text}</option>`
            );
          }
        } else {
          $select.html('');
        }
      }

      if (hasManual) {
        let required = '';
        if ($select.attr('data-required')) {
          required = 'data-required="true"';
        }
        const templateColumnId = $select.attr('data-template-column-id');

        const input = `<input class="form-control manual-input-field" type="text" ${required} disabled data-disabled="true" maxlength="50" data-cleaning-pattern="[^a-zA-Z0-9-]" pattern="^[a-zA-Z0-9-]+$" data-template-column-id="${templateColumnId}">
                               <div class="close-manual" title="Close"><i class="fa fa-times" aria-hidden="true"></i></div>`;
        $select.attr('data-disabled', 'false');
        if ($select.parent().find('input').length) {
          $select.parent().find('input').remove();
        }
        $select.parent().append(input);

        if (isOnly) {
          this.toggleManualInput($select[0]);
        }
      }

      if (this.timeoutForSave[env.attr('data-index')]) {
        clearTimeout(this.timeoutForSave[env.attr('data-index')]);
      }

      this.timeoutForSave[env.attr('data-index')] = setTimeout(() => {
        $select.trigger('selectorChanged');
      }, 200);

      setTimeout(() => {
        collectInputsAndAddListener();
      }, 300);

      this.updateDependency(env, {
        changedElement: selectItem,
        changedDataId: selectItem.dataset.columnId,
        value: selectItem.value,
      });
    });
  }
}
