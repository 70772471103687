/* flatpickr v4.5.2, @license MIT */
/* jshint ignore:start */
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? (module.exports = factory())
    : typeof define === 'function' && define.amd
    ? define(factory)
    : (global.flatpickr = factory());
})(this, function () {
  'use strict';

  var pad = function pad(number) {
    return ('0' + number).slice(-2);
  };
  var int = function int(bool) {
    return bool === true ? 1 : 0;
  };

  function debounce(func, wait, immediate) {
    if (immediate === void 0) {
      immediate = false;
    }

    var timeout;
    return function () {
      var context = this,
        args = arguments;
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = window.setTimeout(function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      }, wait);
      if (immediate && !timeout) func.apply(context, args);
    };
  }

  var arrayify = function arrayify(obj) {
    return obj instanceof Array ? obj : [obj];
  };

  var do_nothing = function do_nothing() {
    return undefined;
  };

  var monthToStr = function monthToStr(monthNumber, shorthand, locale) {
    return locale.months[shorthand ? 'shorthand' : 'longhand'][monthNumber];
  };
  var revFormat = {
    D: do_nothing,
    F: function F(dateObj, monthName, locale) {
      dateObj.setMonth(locale.months.longhand.indexOf(monthName));
    },
    G: function G(dateObj, hour) {
      dateObj.setHours(parseFloat(hour));
    },
    H: function H(dateObj, hour) {
      dateObj.setHours(parseFloat(hour));
    },
    J: function J(dateObj, day) {
      dateObj.setDate(parseFloat(day));
    },
    K: function K(dateObj, amPM, locale) {
      dateObj.setHours((dateObj.getHours() % 12) + 12 * int(new RegExp(locale.amPM[1], 'i').test(amPM)));
    },
    M: function M(dateObj, shortMonth, locale) {
      dateObj.setMonth(locale.months.shorthand.indexOf(shortMonth));
    },
    S: function S(dateObj, seconds) {
      dateObj.setSeconds(parseFloat(seconds));
    },
    U: function U(_, unixSeconds) {
      return new Date(parseFloat(unixSeconds) * 1000);
    },
    W: function W(dateObj, weekNum) {
      var weekNumber = parseInt(weekNum);
      return new Date(dateObj.getFullYear(), 0, 2 + (weekNumber - 1) * 7, 0, 0, 0, 0);
    },
    Y: function Y(dateObj, year) {
      dateObj.setFullYear(parseFloat(year));
    },
    Z: function Z(_, ISODate) {
      return new Date(ISODate);
    },
    d: function d(dateObj, day) {
      dateObj.setDate(parseFloat(day));
    },
    h: function h(dateObj, hour) {
      dateObj.setHours(parseFloat(hour));
    },
    i: function i(dateObj, minutes) {
      dateObj.setMinutes(parseFloat(minutes));
    },
    j: function j(dateObj, day) {
      dateObj.setDate(parseFloat(day));
    },
    l: do_nothing,
    m: function m(dateObj, month) {
      dateObj.setMonth(parseFloat(month) - 1);
    },
    n: function n(dateObj, month) {
      dateObj.setMonth(parseFloat(month) - 1);
    },
    s: function s(dateObj, seconds) {
      dateObj.setSeconds(parseFloat(seconds));
    },
    w: do_nothing,
    y: function y(dateObj, year) {
      dateObj.setFullYear(2000 + parseFloat(year));
    },
  };
  var tokenRegex = {
    D: '(\\w+)',
    F: '(\\w+)',
    G: '(\\d\\d|\\d)',
    H: '(\\d\\d|\\d)',
    J: '(\\d\\d|\\d)\\w+',
    K: '',
    M: '(\\w+)',
    S: '(\\d\\d|\\d)',
    U: '(.+)',
    W: '(\\d\\d|\\d)',
    Y: '(\\d{4})',
    Z: '(.+)',
    d: '(\\d\\d|\\d)',
    h: '(\\d\\d|\\d)',
    i: '(\\d\\d|\\d)',
    j: '(\\d\\d|\\d)',
    l: '(\\w+)',
    m: '(\\d\\d|\\d)',
    n: '(\\d\\d|\\d)',
    s: '(\\d\\d|\\d)',
    w: '(\\d\\d|\\d)',
    y: '(\\d{2})',
  };
  var formats = {
    Z: function Z(date) {
      return date.toISOString();
    },
    D: function D(date, locale, options) {
      return locale.weekdays.shorthand[formats.w(date, locale, options)];
    },
    F: function F(date, locale, options) {
      return monthToStr(formats.n(date, locale, options) - 1, false, locale);
    },
    G: function G(date, locale, options) {
      return pad(formats.h(date, locale, options));
    },
    H: function H(date) {
      return pad(date.getHours());
    },
    J: function J(date, locale) {
      return locale.ordinal !== undefined ? date.getDate() + locale.ordinal(date.getDate()) : date.getDate();
    },
    K: function K(date, locale) {
      return locale.amPM[int(date.getHours() > 11)];
    },
    M: function M(date, locale) {
      return monthToStr(date.getMonth(), true, locale);
    },
    S: function S(date) {
      return pad(date.getSeconds());
    },
    U: function U(date) {
      return date.getTime() / 1000;
    },
    W: function W(date, _, options) {
      return options.getWeek(date);
    },
    Y: function Y(date) {
      return date.getFullYear();
    },
    d: function d(date) {
      return pad(date.getDate());
    },
    h: function h(date) {
      return date.getHours() % 12 ? date.getHours() % 12 : 12;
    },
    i: function i(date) {
      return pad(date.getMinutes());
    },
    j: function j(date) {
      return date.getDate();
    },
    l: function l(date, locale) {
      return locale.weekdays.longhand[date.getDay()];
    },
    m: function m(date) {
      return pad(date.getMonth() + 1);
    },
    n: function n(date) {
      return date.getMonth() + 1;
    },
    s: function s(date) {
      return date.getSeconds();
    },
    w: function w(date) {
      return date.getDay();
    },
    y: function y(date) {
      return String(date.getFullYear()).substring(2);
    },
  };

  var english = {
    weekdays: {
      shorthand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    },
    months: {
      shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      longhand: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
    firstDayOfWeek: 1,
    ordinal: function ordinal(nth) {
      var s = nth % 100;
      if (s > 3 && s < 21) return 'th';

      switch (s % 10) {
        case 1:
          return 'st';

        case 2:
          return 'nd';

        case 3:
          return 'rd';

        default:
          return 'th';
      }
    },
    rangeSeparator: ' to ',
    weekAbbreviation: 'Wk',
    scrollTitle: 'Scroll to increment',
    toggleTitle: 'Click to toggle',
    amPM: ['AM', 'PM'],
    yearAriaLabel: 'Year',
  };

  var createDateFormatter = function createDateFormatter(_ref) {
    var _ref$config = _ref.config,
      config = _ref$config === void 0 ? defaults : _ref$config,
      _ref$l10n = _ref.l10n,
      l10n = _ref$l10n === void 0 ? english : _ref$l10n;
    return function (dateObj, frmt, overrideLocale) {
      var locale = overrideLocale || l10n;

      if (config.formatDate !== undefined) {
        return config.formatDate(dateObj, frmt, locale);
      }

      return frmt
        .split('')
        .map(function (c, i, arr) {
          return formats[c] && arr[i - 1] !== '\\' ? formats[c](dateObj, locale, config) : c !== '\\' ? c : '';
        })
        .join('');
    };
  };
  var createDateParser = function createDateParser(_ref2) {
    var _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? defaults : _ref2$config,
      _ref2$l10n = _ref2.l10n,
      l10n = _ref2$l10n === void 0 ? english : _ref2$l10n;
    return function (date, givenFormat, timeless, customLocale) {
      if (date !== 0 && !date) return undefined;
      var locale = customLocale || l10n;
      var parsedDate;
      var date_orig = date;
      if (date instanceof Date) parsedDate = new Date(date.getTime());
      else if (typeof date !== 'string' && date.toFixed !== undefined) parsedDate = new Date(date);
      else if (typeof date === 'string') {
        var format = givenFormat || (config || defaults).dateFormat;
        var datestr = String(date).trim();

        if (datestr === 'today') {
          parsedDate = new Date();
          timeless = true;
        } else if (/Z$/.test(datestr) || /GMT$/.test(datestr)) parsedDate = new Date(date);
        else if (config && config.parseDate) parsedDate = config.parseDate(date, format);
        else {
          parsedDate =
            !config || !config.noCalendar
              ? new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0)
              : new Date(new Date().setHours(0, 0, 0, 0));
          var matched,
            ops = [];

          for (var i = 0, matchIndex = 0, regexStr = ''; i < format.length; i++) {
            var token = format[i];
            var isBackSlash = token === '\\';
            var escaped = format[i - 1] === '\\' || isBackSlash;

            if (tokenRegex[token] && !escaped) {
              regexStr += tokenRegex[token];
              var match = new RegExp(regexStr).exec(date);

              if (match && (matched = true)) {
                ops[token !== 'Y' ? 'push' : 'unshift']({
                  fn: revFormat[token],
                  val: match[++matchIndex],
                });
              }
            } else if (!isBackSlash) regexStr += '.';

            ops.forEach(function (_ref3) {
              var fn = _ref3.fn,
                val = _ref3.val;
              return (parsedDate = fn(parsedDate, val, locale) || parsedDate);
            });
          }

          parsedDate = matched ? parsedDate : undefined;
        }
      }

      if (!(parsedDate instanceof Date && !isNaN(parsedDate.getTime()))) {
        config.errorHandler(new Error('Invalid date provided: ' + date_orig));
        return undefined;
      }

      if (timeless === true) parsedDate.setHours(0, 0, 0, 0);
      return parsedDate;
    };
  };

  function compareDates(date1, date2, timeless) {
    if (timeless === void 0) {
      timeless = true;
    }

    if (timeless !== false) {
      return new Date(date1.getTime()).setHours(0, 0, 0, 0) - new Date(date2.getTime()).setHours(0, 0, 0, 0);
    }

    return date1.getTime() - date2.getTime();
  }

  var getWeek = function getWeek(givenDate) {
    var date = new Date(givenDate.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    var week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  };
  var isBetween = function isBetween(ts, ts1, ts2) {
    return ts > Math.min(ts1, ts2) && ts < Math.max(ts1, ts2);
  };
  var duration = {
    DAY: 86400000,
  };

  var HOOKS = [
    'onCancel',
    'onChange',
    'onClose',
    'onDayCreate',
    'onDestroy',
    'onKeyDown',
    'onMonthChange',
    'onOpen',
    'onParseConfig',
    'onReady',
    'onValueUpdate',
    'onYearChange',
    'onPreCalendarPosition',
  ];
  var defaults = {
    _disable: [],
    _enable: [],
    allowInput: false,
    altFormat: 'F j, Y',
    altInput: false,
    altInputClass: 'form-control input',
    animate: typeof window === 'object' && window.navigator.userAgent.indexOf('MSIE') === -1,
    ariaDateFormat: 'F j, Y',
    clickOpens: true,
    closeOnSelect: true,
    conjunction: ', ',
    dateFormat: 'Y-m-d',
    defaultHour: 12,
    defaultMinute: 0,
    defaultSeconds: 0,
    disable: [],
    disableMobile: false,
    enable: [],
    enableSeconds: false,
    enableTime: false,
    showTime: false,
    errorHandler: function errorHandler(err) {
      return typeof console !== 'undefined' && console.warn(err);
    },
    getWeek: getWeek,
    hourIncrement: 1,
    ignoredFocusElements: [],
    inline: false,
    locale: 'default',
    minuteIncrement: 5,
    mode: 'single',
    nextArrow:
      "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17 17'><g></g><path d='M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z' /></svg>",
    noCalendar: false,
    now: new Date(),
    onChange: [],
    onClose: [],
    onCancel: [],
    onDayCreate: [],
    onDestroy: [],
    onKeyDown: [],
    onMonthChange: [],
    onOpen: [],
    onParseConfig: [],
    onReady: [],
    onValueUpdate: [],
    onYearChange: [],
    onPreCalendarPosition: [],
    plugins: [],
    position: 'auto',
    positionElement: undefined,
    prevArrow:
      "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17 17'><g></g><path d='M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z' /></svg>",
    shorthandCurrentMonth: false,
    showMonths: 1,
    static: false,
    time_24hr: false,
    weekNumbers: false,
    wrap: false,
  };

  function toggleClass(elem, className, bool) {
    if (bool === true) return elem.classList.add(className);
    elem.classList.remove(className);
  }

  function createElement(tag, className, content) {
    var e = window.document.createElement(tag);
    className = className || '';
    content = content || '';
    e.className = className;
    if (content !== undefined) e.textContent = content;
    return e;
  }

  function clearNode(node) {
    while (node.firstChild) {
      node.removeChild(node.firstChild);
    }
  }

  function findParent(node, condition) {
    if (condition(node)) return node;
    else if (node.parentNode) return findParent(node.parentNode, condition);
    return undefined;
  }

  function createNumberInput(inputClassName, opts) {
    var wrapper = createElement('div', 'numInputWrapper'),
      numInput = createElement('input', 'numInput ' + inputClassName),
      arrowUp = createElement('span', 'arrowUp'),
      arrowDown = createElement('span', 'arrowDown');
    numInput.type = 'text';
    numInput.pattern = '\\d*';
    if (opts !== undefined)
      for (var key in opts) {
        numInput.setAttribute(key, opts[key]);
      }
    wrapper.appendChild(numInput);
    wrapper.appendChild(arrowUp);
    wrapper.appendChild(arrowDown);
    return wrapper;
  }

  if (typeof Object.assign !== 'function') {
    Object.assign = function (target) {
      if (!target) {
        throw TypeError('Cannot convert undefined or null to object');
      }

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var _loop = function _loop() {
        var source = args[_i];

        if (source) {
          Object.keys(source).forEach(function (key) {
            target[key] = source[key];
            return source[key];
          });
        }
      };

      for (var _i = 0; _i < args.length; _i++) {
        _loop();
      }

      return target;
    };
  }

  var DEBOUNCED_CHANGE_MS = 300;

  function FlatpickrInstance(element, instanceConfig) {
    var self = {
      config: Object.assign({}, flatpickr.defaultConfig),
      l10n: english,
    };
    self.parseDate = createDateParser({
      config: self.config,
      l10n: self.l10n,
    });
    self._handlers = [];
    self._bind = bind;
    self._setHoursFromDate = setHoursFromDate;
    self._positionCalendar = positionCalendar;
    self.changeMonth = changeMonth;
    self.changeYear = changeYear;
    self.clear = clear;
    self.close = close;
    self._createElement = createElement;
    self.destroy = destroy;
    self.isEnabled = isEnabled;
    self.jumpToDate = jumpToDate;
    self.open = open;
    self.redraw = redraw;
    self.set = set;
    self.setDate = setDate;
    self.toggle = toggle;

    function setupHelperFunctions() {
      self.utils = {
        getDaysInMonth: function getDaysInMonth(month, yr) {
          if (month === void 0) {
            month = self.currentMonth;
          }

          if (yr === void 0) {
            yr = self.currentYear;
          }

          if (month === 1 && ((yr % 4 === 0 && yr % 100 !== 0) || yr % 400 === 0)) return 29;
          return self.l10n.daysInMonth[month];
        },
      };
    }

    function init() {
      self.element = self.input = element;
      self.isOpen = false;
      parseConfig();
      setupLocale();
      setupInputs();
      setupDates();
      setupHelperFunctions();
      if (!self.isMobile) build();
      bindEvents();

      if (self.selectedDates.length || self.config.noCalendar) {
        if (self.config.enableTime) {
          setHoursFromDate(self.config.noCalendar ? self.latestSelectedDateObj || self.config.minDate : undefined);
        }

        updateValue(false);
      }

      setCalendarWidth();
      self.showTimeInput = self.selectedDates.length > 0 || self.config.noCalendar;
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (!self.isMobile && isSafari) {
        positionCalendar();
      }

      triggerEvent('onReady');
    }

    function bindToInstance(fn) {
      return fn.bind(self);
    }

    function setCalendarWidth() {
      var config = self.config;
      if (config.weekNumbers === false && config.showMonths === 1) return;
      else if (config.noCalendar !== true) {
        window.requestAnimationFrame(function () {
          self.calendarContainer.style.visibility = 'hidden';
          self.calendarContainer.style.display = 'block';

          if (self.daysContainer !== undefined) {
            var daysWidth = (self.days.offsetWidth + 1) * config.showMonths;
            self.daysContainer.style.width = daysWidth + 'px';
            self.calendarContainer.style.width =
              daysWidth + (self.weekWrapper !== undefined ? self.weekWrapper.offsetWidth : 0) + 'px';
            self.calendarContainer.style.removeProperty('visibility');
            self.calendarContainer.style.removeProperty('display');
          }
        });
      }
    }

    function updateTime(e) {
      if (self.selectedDates.length === 0) return;

      if (e !== undefined && e.type !== 'blur') {
        timeWrapper(e);
      }

      var prevValue = self._input.value;
      setHoursFromInputs();
      updateValue();

      if (self._input.value !== prevValue) {
        self._debouncedChange();
      }
    }

    function ampm2military(hour, amPM) {
      return (hour % 12) + 12 * int(amPM === self.l10n.amPM[1]);
    }

    function military2ampm(hour) {
      switch (hour % 24) {
        case 0:
        case 12:
          return 12;

        default:
          return hour % 12;
      }
    }

    function setHoursFromInputs() {
      if (self.hourElement === undefined || self.minuteElement === undefined) return;
      var hours = (parseInt(self.hourElement.value.slice(-2), 10) || 0) % 24,
        minutes = (parseInt(self.minuteElement.value, 10) || 0) % 60,
        seconds = self.secondElement !== undefined ? (parseInt(self.secondElement.value, 10) || 0) % 60 : 0;

      if (self.amPM !== undefined) {
        hours = ampm2military(hours, self.amPM.textContent);
      }

      var limitMinHours =
        self.config.minTime !== undefined ||
        (self.config.minDate &&
          self.minDateHasTime &&
          self.latestSelectedDateObj &&
          compareDates(self.latestSelectedDateObj, self.config.minDate, true) === 0);
      var limitMaxHours =
        self.config.maxTime !== undefined ||
        (self.config.maxDate &&
          self.maxDateHasTime &&
          self.latestSelectedDateObj &&
          compareDates(self.latestSelectedDateObj, self.config.maxDate, true) === 0);

      if (limitMaxHours) {
        var maxTime = self.config.maxTime !== undefined ? self.config.maxTime : self.config.maxDate;
        hours = Math.min(hours, maxTime.getHours());
        if (hours === maxTime.getHours()) minutes = Math.min(minutes, maxTime.getMinutes());
        if (minutes === maxTime.getMinutes()) seconds = Math.min(seconds, maxTime.getSeconds());
      }

      if (limitMinHours) {
        var minTime = self.config.minTime !== undefined ? self.config.minTime : self.config.minDate;
        hours = Math.max(hours, minTime.getHours());
        if (hours === minTime.getHours()) minutes = Math.max(minutes, minTime.getMinutes());
        if (minutes === minTime.getMinutes()) seconds = Math.max(seconds, minTime.getSeconds());
      }

      setHours(hours, minutes, seconds);
    }

    function setHoursFromDate(dateObj) {
      var date = dateObj || self.latestSelectedDateObj;
      if (date) setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    }

    function setDefaultHours() {
      var hours = self.config.defaultHour;
      var minutes = self.config.defaultMinute;
      var seconds = self.config.defaultSeconds;

      if (self.config.minDate !== undefined) {
        var min_hr = self.config.minDate.getHours();
        var min_minutes = self.config.minDate.getMinutes();
        hours = Math.max(hours, min_hr);
        if (hours === min_hr) minutes = Math.max(min_minutes, minutes);
        if (hours === min_hr && minutes === min_minutes) seconds = self.config.minDate.getSeconds();
      }

      if (self.config.maxDate !== undefined) {
        var max_hr = self.config.maxDate.getHours();
        var max_minutes = self.config.maxDate.getMinutes();
        hours = Math.min(hours, max_hr);
        if (hours === max_hr) minutes = Math.min(max_minutes, minutes);
        if (hours === max_hr && minutes === max_minutes) seconds = self.config.maxDate.getSeconds();
      }

      setHours(hours, minutes, seconds);
    }

    function setHours(hours, minutes, seconds) {
      if (self.latestSelectedDateObj !== undefined) {
        self.latestSelectedDateObj.setHours(hours % 24, minutes, seconds || 0, 0);
      }

      if (!self.hourElement || !self.minuteElement || self.isMobile) return;
      self.hourElement.value = pad(!self.config.time_24hr ? ((12 + hours) % 12) + 12 * int(hours % 12 === 0) : hours);
      self.minuteElement.value = pad(minutes);
      if (self.amPM !== undefined) self.amPM.textContent = self.l10n.amPM[int(hours >= 12)];
      if (self.secondElement !== undefined) self.secondElement.value = pad(seconds);
    }

    function onYearInput(event) {
      var year = parseInt(event.target.value) + (event.delta || 0);

      if (year / 1000 > 1 || (event.key === 'Enter' && !/[^\d]/.test(year.toString()))) {
        changeYear(year);
      }
    }

    function bind(element, event, handler, options) {
      if (event instanceof Array)
        return event.forEach(function (ev) {
          return bind(element, ev, handler, options);
        });
      if (element instanceof Array)
        return element.forEach(function (el) {
          return bind(el, event, handler, options);
        });
      element.addEventListener(event, handler, options);

      self._handlers.push({
        element: element,
        event: event,
        handler: handler,
        options: options,
      });
    }

    function onClick(handler) {
      return function (evt) {
        if (evt.which === 1) {
          handler(evt);
        }
      };
    }

    function triggerChange() {
      triggerEvent('onChange');
    }

    function bindEvents() {
      if (self.config.wrap) {
        ['open', 'close', 'toggle', 'clear'].forEach(function (evt) {
          Array.prototype.forEach.call(self.element.querySelectorAll('[data-' + evt + ']'), function (el) {
            return bind(el, 'click', self[evt]);
          });
        });
      }

      if (self.isMobile) {
        setupMobile();
        return;
      }

      var debouncedResize = debounce(onResize, 50);
      self._debouncedChange = debounce(triggerChange, DEBOUNCED_CHANGE_MS);
      if (self.daysContainer && !/iPhone|iPad|iPod/i.test(navigator.userAgent))
        bind(self.daysContainer, 'mouseover', function (e) {
          if (self.config.mode === 'range') onMouseOver(e.target);
        });
      bind(window.document.body, 'keydown', onKeyDown);
      if (!self.config.static) bind(self._input, 'keydown', onKeyDown);
      if (!self.config.inline && !self.config.static) bind(window, 'resize', debouncedResize);
      if (window.ontouchstart !== undefined) bind(window.document, 'click', documentClick);
      else bind(window.document, 'mousedown', onClick(documentClick));
      bind(window.document, 'focus', documentClick, {
        capture: true,
      });

      if (self.config.clickOpens === true) {
        bind(self._input, 'focus', self.open);
        bind(self._input, 'mousedown', onClick(self.open));
      }

      if (self.daysContainer !== undefined) {
        bind(self.monthNav, 'mousedown', onClick(onMonthNavClick));
        bind(self.monthNav, ['keyup', 'increment'], onYearInput);
        bind(self.daysContainer, 'mousedown', onClick(selectDate));
      }
      if (self.timeContainer !== undefined && self.minuteElement !== undefined && self.hourElement !== undefined) {
        var selText = function selText(e) {
          return e.target.select();
        };

        bind(self.timeContainer, ['increment'], updateTime);
        bind(self.timeContainer, 'blur', updateTime, {
          capture: true,
        });
        bind(self.timeContainer, 'mousedown', onClick(timeIncrement));
        bind([self.hourElement, self.minuteElement], ['focus', 'click'], selText);
        if (self.secondElement !== undefined)
          bind(self.secondElement, 'focus', function () {
            return self.secondElement && self.secondElement.select();
          });

        if (self.amPM !== undefined) {
          bind(
            self.amPM,
            'mousedown',
            onClick(function (e) {
              updateTime(e);
              triggerChange();
            })
          );
        }
      }
    }

    function jumpToDate(jumpDate) {
      var jumpTo =
        jumpDate !== undefined
          ? self.parseDate(jumpDate)
          : self.latestSelectedDateObj ||
            (self.config.minDate && self.config.minDate > self.now
              ? self.config.minDate
              : self.config.maxDate && self.config.maxDate < self.now
              ? self.config.maxDate
              : self.now);

      try {
        if (jumpTo !== undefined) {
          self.currentYear = jumpTo.getFullYear();
          self.currentMonth = jumpTo.getMonth();
        }
      } catch (e) {
        e.message = 'Invalid date supplied: ' + jumpTo;
        self.config.errorHandler(e);
      }

      self.redraw();
    }

    function timeIncrement(e) {
      if (~e.target.className.indexOf('arrow')) incrementNumInput(e, e.target.classList.contains('arrowUp') ? 1 : -1);
    }

    function incrementNumInput(e, delta, inputElem) {
      var target = e && e.target;
      var input = inputElem || (target && target.parentNode && target.parentNode.firstChild);
      var event = createEvent('increment');
      event.delta = delta;
      if (input) {
        input.dispatchEvent(event);
      }
    }

    function build() {
      var fragment = window.document.createDocumentFragment();
      self.calendarContainer = createElement('div', 'flatpickr-calendar');
      self.calendarContainer.tabIndex = -1;

      if (!self.config.noCalendar) {
        fragment.appendChild(buildMonthNav());
        self.innerContainer = createElement('div', 'flatpickr-innerContainer');

        if (self.config.weekNumbers) {
          var _buildWeeks = buildWeeks(),
            weekWrapper = _buildWeeks.weekWrapper,
            weekNumbers = _buildWeeks.weekNumbers;

          self.innerContainer.appendChild(weekWrapper);
          self.weekNumbers = weekNumbers;
          self.weekWrapper = weekWrapper;
        }

        self.rContainer = createElement('div', 'flatpickr-rContainer');
        self.rContainer.appendChild(buildWeekdays());

        if (!self.daysContainer) {
          self.daysContainer = createElement('div', 'flatpickr-days');
          self.daysContainer.tabIndex = -1;
        }

        buildDays();
        fragment.appendChild(self.innerContainer);
      }

      if (self.config.enableTime) {
        if (self.config.showTime) {
          fragment.appendChild(buildTime());
        }
      }

      toggleClass(self.calendarContainer, 'rangeMode', self.config.mode === 'range');
      toggleClass(self.calendarContainer, 'animate', self.config.animate === true);
      toggleClass(self.calendarContainer, 'multiMonth', self.config.showMonths > 1);
      self.calendarContainer.appendChild(fragment);

      self.rContainer.appendChild(self.daysContainer);
      self.innerContainer.appendChild(self.rContainer);

      var customAppend = self.config.appendTo !== undefined && self.config.appendTo.nodeType !== undefined;

      if (self.config.inline || self.config.static) {
        self.calendarContainer.classList.add(self.config.inline ? 'inline' : 'static');

        if (self.config.inline) {
          if (!customAppend && self.element.parentNode)
            self.element.parentNode.insertBefore(self.calendarContainer, self._input.nextSibling);
          else if (self.config.appendTo !== undefined) self.config.appendTo.appendChild(self.calendarContainer);
        }

        if (self.config.static) {
          var wrapper = createElement('div', 'flatpickr-wrapper');
          if (self.element.parentNode) self.element.parentNode.insertBefore(wrapper, self.element);
          wrapper.appendChild(self.element);
          if (self.altInput) wrapper.appendChild(self.altInput);
          wrapper.appendChild(self.calendarContainer);
        }
      }

      if (!self.config.static && !self.config.inline)
        (self.config.appendTo !== undefined ? self.config.appendTo : window.document.body).appendChild(
          self.calendarContainer
        );
    }

    function createDay(className, date, dayNumber, i) {
      var dateIsEnabled = isEnabled(date, true),
        dayElement = createElement('span', 'flatpickr-day ' + className, date.getDate().toString());
      dayElement.dateObj = date;
      dayElement.$i = i;
      dayElement.setAttribute('aria-label', self.formatDate(date, self.config.ariaDateFormat));

      if (className.indexOf('hidden') === -1 && compareDates(date, self.now) === 0) {
        self.todayDateElem = dayElement;
        dayElement.classList.add('today');
        dayElement.setAttribute('aria-current', 'date');
      }

      if (dateIsEnabled) {
        dayElement.tabIndex = -1;

        if (isDateSelected(date)) {
          dayElement.classList.add('selected');
          self.selectedDateElem = dayElement;

          if (self.config.mode === 'range') {
            toggleClass(
              dayElement,
              'startRange',
              self.selectedDates[0] && compareDates(date, self.selectedDates[0], true) === 0
            );
            toggleClass(
              dayElement,
              'endRange',
              self.selectedDates[1] && compareDates(date, self.selectedDates[1], true) === 0
            );
            if (className === 'nextMonthDay') dayElement.classList.add('inRange');
          }
        }
      } else {
        dayElement.classList.add('disabled');
      }

      if (self.config.mode === 'range') {
        if (isDateInRange(date) && !isDateSelected(date)) dayElement.classList.add('inRange');
      }

      if (self.weekNumbers && self.config.showMonths === 1 && className !== 'prevMonthDay' && dayNumber % 7 === 1) {
        self.weekNumbers.insertAdjacentHTML(
          'beforeend',
          "<span class='flatpickr-day'>" + self.config.getWeek(date) + '</span>'
        );
      }

      triggerEvent('onDayCreate', dayElement);
      return dayElement;
    }

    function focusOnDayElem(targetNode) {
      targetNode.focus();
      if (self.config.mode === 'range') onMouseOver(targetNode);
    }

    function getFirstAvailableDay(delta) {
      var startMonth = delta > 0 ? 0 : self.config.showMonths - 1;
      var endMonth = delta > 0 ? self.config.showMonths : -1;

      for (var m = startMonth; m != endMonth; m += delta) {
        var month = self.daysContainer.children[m];
        var startIndex = delta > 0 ? 0 : month.children.length - 1;
        var endIndex = delta > 0 ? month.children.length : -1;

        for (var i = startIndex; i != endIndex; i += delta) {
          var c = month.children[i];
          if (c.className.indexOf('hidden') === -1 && isEnabled(c.dateObj)) return c;
        }
      }

      return undefined;
    }

    function getNextAvailableDay(current, delta) {
      var givenMonth = current.className.indexOf('Month') === -1 ? current.dateObj.getMonth() : self.currentMonth;
      var endMonth = delta > 0 ? self.config.showMonths : -1;
      var loopDelta = delta > 0 ? 1 : -1;

      for (var m = givenMonth - self.currentMonth; m != endMonth; m += loopDelta) {
        var month = self.daysContainer.children[m];
        var startIndex =
          givenMonth - self.currentMonth === m ? current.$i + delta : delta < 0 ? month.children.length - 1 : 0;
        var numMonthDays = month.children.length;

        for (var i = startIndex; i >= 0 && i < numMonthDays && i != (delta > 0 ? numMonthDays : -1); i += loopDelta) {
          var c = month.children[i];
          if (
            c.className.indexOf('hidden') === -1 &&
            isEnabled(c.dateObj) &&
            Math.abs(current.$i - i) >= Math.abs(delta)
          )
            return focusOnDayElem(c);
        }
      }

      self.changeMonth(loopDelta);
      focusOnDay(getFirstAvailableDay(loopDelta), 0);
      return undefined;
    }

    function focusOnDay(current, offset) {
      var dayFocused = isInView(document.activeElement || document.body);
      var startElem =
        current !== undefined
          ? current
          : dayFocused
          ? document.activeElement
          : self.selectedDateElem !== undefined && isInView(self.selectedDateElem)
          ? self.selectedDateElem
          : self.todayDateElem !== undefined && isInView(self.todayDateElem)
          ? self.todayDateElem
          : getFirstAvailableDay(offset > 0 ? 1 : -1);
      if (startElem === undefined) return self._input.focus();
      if (!dayFocused) return focusOnDayElem(startElem);
      getNextAvailableDay(startElem, offset);
    }

    function buildMonthDays(year, month) {
      var firstOfMonth = (new Date(year, month, 1).getDay() - self.l10n.firstDayOfWeek + 7) % 7;
      var prevMonthDays = self.utils.getDaysInMonth((month - 1 + 12) % 12);
      var daysInMonth = self.utils.getDaysInMonth(month),
        days = window.document.createDocumentFragment(),
        isMultiMonth = self.config.showMonths > 1,
        prevMonthDayClass = isMultiMonth ? 'prevMonthDay hidden' : 'prevMonthDay',
        nextMonthDayClass = isMultiMonth ? 'nextMonthDay hidden' : 'nextMonthDay';
      var dayNumber = prevMonthDays + 1 - firstOfMonth,
        dayIndex = 0;

      for (; dayNumber <= prevMonthDays; dayNumber++, dayIndex++) {
        days.appendChild(createDay(prevMonthDayClass, new Date(year, month - 1, dayNumber), dayNumber, dayIndex));
      }

      for (dayNumber = 1; dayNumber <= daysInMonth; dayNumber++, dayIndex++) {
        days.appendChild(createDay('', new Date(year, month, dayNumber), dayNumber, dayIndex));
      }

      for (
        var dayNum = daysInMonth + 1;
        dayNum <= 42 - firstOfMonth && (self.config.showMonths === 1 || dayIndex % 7 !== 0);
        dayNum++, dayIndex++
      ) {
        days.appendChild(
          createDay(nextMonthDayClass, new Date(year, month + 1, dayNum % daysInMonth), dayNum, dayIndex)
        );
      }

      var dayContainer = createElement('div', 'dayContainer');
      dayContainer.appendChild(days);
      return dayContainer;
    }

    function buildDays() {
      if (self.daysContainer === undefined) {
        return;
      }

      clearNode(self.daysContainer);
      if (self.weekNumbers) clearNode(self.weekNumbers);
      var frag = document.createDocumentFragment();

      for (var i = 0; i < self.config.showMonths; i++) {
        var d = new Date(self.currentYear, self.currentMonth, 1);
        d.setMonth(self.currentMonth + i);
        frag.appendChild(buildMonthDays(d.getFullYear(), d.getMonth()));
      }

      self.daysContainer.appendChild(frag);
      self.days = self.daysContainer.firstChild;

      if (self.config.mode === 'range' && self.selectedDates.length === 1) {
        onMouseOver();
      }
    }

    function buildMonth() {
      var container = createElement('div', 'flatpickr-month');
      var monthNavFragment = window.document.createDocumentFragment();
      var monthElement = createElement('span', 'cur-month');
      var yearInput = createNumberInput('cur-year', {
        tabindex: '-1',
      });
      var yearElement = yearInput.getElementsByTagName('input')[0];
      yearElement.setAttribute('aria-label', self.l10n.yearAriaLabel);
      if (self.config.minDate) yearElement.setAttribute('data-min', self.config.minDate.getFullYear().toString());

      if (self.config.maxDate) {
        yearElement.setAttribute('data-max', self.config.maxDate.getFullYear().toString());
        yearElement.disabled =
          !!self.config.minDate && self.config.minDate.getFullYear() === self.config.maxDate.getFullYear();
      }

      var currentMonth = createElement('div', 'flatpickr-current-month');
      currentMonth.appendChild(monthElement);
      currentMonth.appendChild(yearInput);
      monthNavFragment.appendChild(currentMonth);
      container.appendChild(monthNavFragment);
      return {
        container: container,
        yearElement: yearElement,
        monthElement: monthElement,
      };
    }

    function buildMonths() {
      clearNode(self.monthNav);
      self.monthNav.appendChild(self.prevMonthNav);

      for (var m = self.config.showMonths; m--; ) {
        var month = buildMonth();
        self.yearElements.push(month.yearElement);
        self.monthElements.push(month.monthElement);
        self.monthNav.appendChild(month.container);
      }

      self.monthNav.appendChild(self.nextMonthNav);
    }

    function buildMonthNav() {
      self.monthNav = createElement('div', 'flatpickr-months');
      self.yearElements = [];
      self.monthElements = [];
      self.prevMonthNav = createElement('span', 'flatpickr-prev-month');
      self.prevMonthNav.innerHTML = self.config.prevArrow;
      self.nextMonthNav = createElement('span', 'flatpickr-next-month');
      self.nextMonthNav.innerHTML = self.config.nextArrow;
      buildMonths();
      Object.defineProperty(self, '_hidePrevMonthArrow', {
        get: function get() {
          return self.__hidePrevMonthArrow;
        },
        set: function set(bool) {
          if (self.__hidePrevMonthArrow !== bool) {
            toggleClass(self.prevMonthNav, 'disabled', bool);
            self.__hidePrevMonthArrow = bool;
          }
        },
      });
      Object.defineProperty(self, '_hideNextMonthArrow', {
        get: function get() {
          return self.__hideNextMonthArrow;
        },
        set: function set(bool) {
          if (self.__hideNextMonthArrow !== bool) {
            toggleClass(self.nextMonthNav, 'disabled', bool);
            self.__hideNextMonthArrow = bool;
          }
        },
      });
      self.currentYearElement = self.yearElements[0];
      updateNavigationCurrentMonth();
      return self.monthNav;
    }

    function buildTime() {
      self.calendarContainer.classList.add('hasTime');
      if (self.config.noCalendar) self.calendarContainer.classList.add('noCalendar');
      self.timeContainer = createElement('div', 'flatpickr-time');
      self.timeContainer.tabIndex = -1;
      var separator = createElement('span', 'flatpickr-time-separator', ':');
      var hourInput = createNumberInput('flatpickr-hour');
      self.hourElement = hourInput.getElementsByTagName('input')[0];
      var minuteInput = createNumberInput('flatpickr-minute');
      self.minuteElement = minuteInput.getElementsByTagName('input')[0];
      self.hourElement.tabIndex = self.minuteElement.tabIndex = -1;
      self.hourElement.value = pad(
        self.latestSelectedDateObj
          ? self.latestSelectedDateObj.getHours()
          : self.config.time_24hr
          ? self.config.defaultHour
          : military2ampm(self.config.defaultHour)
      );
      self.minuteElement.value = pad(
        self.latestSelectedDateObj ? self.latestSelectedDateObj.getMinutes() : self.config.defaultMinute
      );
      self.hourElement.setAttribute('data-step', self.config.hourIncrement.toString());
      self.minuteElement.setAttribute('data-step', self.config.minuteIncrement.toString());
      self.hourElement.setAttribute('data-min', self.config.time_24hr ? '0' : '1');
      self.hourElement.setAttribute('data-max', self.config.time_24hr ? '23' : '12');
      self.minuteElement.setAttribute('data-min', '0');
      self.minuteElement.setAttribute('data-max', '59');
      self.timeContainer.appendChild(hourInput);
      self.timeContainer.appendChild(separator);
      self.timeContainer.appendChild(minuteInput);
      if (self.config.time_24hr) self.timeContainer.classList.add('time24hr');

      if (self.config.enableSeconds) {
        self.timeContainer.classList.add('hasSeconds');
        var secondInput = createNumberInput('flatpickr-second');
        self.secondElement = secondInput.getElementsByTagName('input')[0];
        self.secondElement.value = pad(
          self.latestSelectedDateObj ? self.latestSelectedDateObj.getSeconds() : self.config.defaultSeconds
        );
        self.secondElement.setAttribute('data-step', self.minuteElement.getAttribute('data-step'));
        self.secondElement.setAttribute('data-min', self.minuteElement.getAttribute('data-min'));
        self.secondElement.setAttribute('data-max', self.minuteElement.getAttribute('data-max'));
        self.timeContainer.appendChild(createElement('span', 'flatpickr-time-separator', ':'));
        self.timeContainer.appendChild(secondInput);
      }

      if (!self.config.time_24hr) {
        self.amPM = createElement(
          'span',
          'flatpickr-am-pm',
          self.l10n.amPM[int((self.latestSelectedDateObj ? self.hourElement.value : self.config.defaultHour) > 11)]
        );
        self.amPM.title = self.l10n.toggleTitle;
        self.amPM.tabIndex = -1;
        self.timeContainer.appendChild(self.amPM);
      }

      return self.timeContainer;
    }

    function buildWeekdays() {
      if (!self.weekdayContainer) self.weekdayContainer = createElement('div', 'flatpickr-weekdays');
      else clearNode(self.weekdayContainer);

      for (var i = self.config.showMonths; i--; ) {
        var container = createElement('div', 'flatpickr-weekdaycontainer');
        self.weekdayContainer.appendChild(container);
      }

      updateWeekdays();
      return self.weekdayContainer;
    }

    function updateWeekdays() {
      var firstDayOfWeek = self.l10n.firstDayOfWeek;
      var weekdays = self.l10n.weekdays.shorthand.concat();

      if (firstDayOfWeek > 0 && firstDayOfWeek < weekdays.length) {
        weekdays = weekdays.splice(firstDayOfWeek, weekdays.length).concat(weekdays.splice(0, firstDayOfWeek));
      }

      for (var i = self.config.showMonths; i--; ) {
        self.weekdayContainer.children[i].innerHTML =
          '\n      <span class=flatpickr-weekday>\n        ' +
          weekdays.join('</span><span class=flatpickr-weekday>') +
          '\n      </span>\n      ';
      }
    }

    function buildWeeks() {
      self.calendarContainer.classList.add('hasWeeks');
      var weekWrapper = createElement('div', 'flatpickr-weekwrapper');
      weekWrapper.appendChild(createElement('span', 'flatpickr-weekday', self.l10n.weekAbbreviation));
      var weekNumbers = createElement('div', 'flatpickr-weeks');
      weekWrapper.appendChild(weekNumbers);
      return {
        weekWrapper: weekWrapper,
        weekNumbers: weekNumbers,
      };
    }

    function changeMonth(value, is_offset) {
      if (is_offset === void 0) {
        is_offset = true;
      }

      var delta = is_offset ? value : value - self.currentMonth;
      if ((delta < 0 && self._hidePrevMonthArrow === true) || (delta > 0 && self._hideNextMonthArrow === true)) return;
      self.currentMonth += delta;

      if (self.currentMonth < 0 || self.currentMonth > 11) {
        self.currentYear += self.currentMonth > 11 ? 1 : -1;
        self.currentMonth = (self.currentMonth + 12) % 12;
        triggerEvent('onYearChange');
      }

      buildDays();
      triggerEvent('onMonthChange');
      updateNavigationCurrentMonth();
    }

    function clear(triggerChangeEvent) {
      if (triggerChangeEvent === void 0) {
        triggerChangeEvent = true;
      }

      self.input.value = '';
      if (self.altInput !== undefined) self.altInput.value = '';
      if (self.mobileInput !== undefined) self.mobileInput.value = '';
      self.selectedDates = [];
      self.latestSelectedDateObj = undefined;
      self.showTimeInput = false;

      if (self.config.enableTime === true) {
        setDefaultHours();
      }

      self.redraw();
      if (triggerChangeEvent) triggerEvent('onChange');
    }

    function close(data) {
      self.isOpen = false;

      if (!self.isMobile) {
        self.calendarContainer.classList.remove('open');

        self._input.classList.remove('active');
      }

      triggerEvent('onClose');
    }

    function destroy() {
      if (self.config !== undefined) triggerEvent('onDestroy');

      for (var i = self._handlers.length; i--; ) {
        var h = self._handlers[i];
        h.element.removeEventListener(h.event, h.handler, h.options);
      }

      self._handlers = [];

      if (self.mobileInput) {
        if (self.mobileInput.parentNode) self.mobileInput.parentNode.removeChild(self.mobileInput);
        self.mobileInput = undefined;
      } else if (self.calendarContainer && self.calendarContainer.parentNode) {
        if (self.config.static && self.calendarContainer.parentNode) {
          var wrapper = self.calendarContainer.parentNode;
          if (wrapper.lastChild) {
            wrapper.removeChild(wrapper.lastChild);
          }

          if (wrapper.parentNode) {
            while (wrapper.firstChild) {
              wrapper.parentNode.insertBefore(wrapper.firstChild, wrapper);
            }

            wrapper.parentNode.removeChild(wrapper);
          }
        } else self.calendarContainer.parentNode.removeChild(self.calendarContainer);
      }

      if (self.altInput) {
        self.input.type = 'text';
        if (self.altInput.parentNode) self.altInput.parentNode.removeChild(self.altInput);
        delete self.altInput;
      }

      if (self.input) {
        self.input.type = self.input._type;
        self.input.classList.remove('flatpickr-input');
        self.input.removeAttribute('readonly');
        self.input.value = '';
      }

      [
        '_showTimeInput',
        'latestSelectedDateObj',
        '_hideNextMonthArrow',
        '_hidePrevMonthArrow',
        '__hideNextMonthArrow',
        '__hidePrevMonthArrow',
        'isMobile',
        'isOpen',
        'selectedDateElem',
        'minDateHasTime',
        'maxDateHasTime',
        'days',
        'daysContainer',
        '_input',
        '_positionElement',
        'innerContainer',
        'rContainer',
        'monthNav',
        'todayDateElem',
        'calendarContainer',
        'weekdayContainer',
        'prevMonthNav',
        'nextMonthNav',
        'currentMonthElement',
        'currentYearElement',
        'navigationCurrentMonth',
        'selectedDateElem',
        'config',
      ].forEach(function (k) {
        try {
          delete self[k];
        } catch (_) {}
      });
    }

    function isCalendarElem(elem) {
      if (self.config.appendTo && self.config.appendTo.contains(elem)) return true;
      return self.calendarContainer.contains(elem);
    }

    function documentClick(e) {
      if (self.isOpen && !self.config.inline) {
        var isCalendarElement = isCalendarElem(e.target);
        var isInput =
          e.target === self.input ||
          e.target === self.altInput ||
          self.element.contains(e.target) ||
          (e.path && e.path.indexOf && (~e.path.indexOf(self.input) || ~e.path.indexOf(self.altInput)));
        var lostFocus =
          e.type === 'blur'
            ? isInput && e.relatedTarget && !isCalendarElem(e.relatedTarget)
            : !isInput && !isCalendarElement;
        var isIgnored = !self.config.ignoredFocusElements.some(function (elem) {
          return elem.contains(e.target);
        });

        if (lostFocus && isIgnored) {
          self.close();

          if (self.config.mode === 'range' && self.selectedDates.length === 1) {
            // self.clear(false);
            // self.redraw();
            focusAndCloseEsc();
          }
        }
      }
    }

    function changeYear(newYear) {
      if (
        !newYear ||
        (self.config.minDate && newYear < self.config.minDate.getFullYear()) ||
        (self.config.maxDate && newYear > self.config.maxDate.getFullYear())
      )
        return;
      var newYearNum = newYear,
        isNewYear = self.currentYear !== newYearNum;
      self.currentYear = newYearNum || self.currentYear;

      if (self.config.maxDate && self.currentYear === self.config.maxDate.getFullYear()) {
        self.currentMonth = Math.min(self.config.maxDate.getMonth(), self.currentMonth);
      } else if (self.config.minDate && self.currentYear === self.config.minDate.getFullYear()) {
        self.currentMonth = Math.max(self.config.minDate.getMonth(), self.currentMonth);
      }

      if (isNewYear) {
        self.redraw();
        triggerEvent('onYearChange');
      }
    }

    function isEnabled(date, timeless) {
      if (timeless === void 0) {
        timeless = true;
      }

      var dateToCheck = self.parseDate(date, undefined, timeless);
      if (
        (self.config.minDate &&
          dateToCheck &&
          compareDates(dateToCheck, self.config.minDate, timeless !== undefined ? timeless : !self.minDateHasTime) <
            0) ||
        (self.config.maxDate &&
          dateToCheck &&
          compareDates(dateToCheck, self.config.maxDate, timeless !== undefined ? timeless : !self.maxDateHasTime) > 0)
      )
        return false;
      if (self.config.enable.length === 0 && self.config.disable.length === 0) return true;
      if (dateToCheck === undefined) return false;
      var bool = self.config.enable.length > 0,
        array = bool ? self.config.enable : self.config.disable;

      for (var i = 0, d; i < array.length; i++) {
        d = array[i];
        if (typeof d === 'function' && d(dateToCheck)) return bool;
        else if (d instanceof Date && dateToCheck !== undefined && d.getTime() === dateToCheck.getTime()) return bool;
        else if (typeof d === 'string' && dateToCheck !== undefined) {
          var parsed = self.parseDate(d, undefined, true);
          return parsed && parsed.getTime() === dateToCheck.getTime() ? bool : !bool;
        } else if (
          typeof d === 'object' &&
          dateToCheck !== undefined &&
          d.from &&
          d.to &&
          dateToCheck.getTime() >= d.from.getTime() &&
          dateToCheck.getTime() <= d.to.getTime()
        )
          return bool;
      }

      return !bool;
    }

    function isInView(elem) {
      if (self.daysContainer !== undefined)
        return elem.className.indexOf('hidden') === -1 && self.daysContainer.contains(elem);
      return false;
    }

    function onKeyDown(e) {
      var isInput = e.target === self._input;
      var allowInput = self.config.allowInput;
      var allowKeydown = self.isOpen && (!allowInput || !isInput);
      var allowInlineKeydown = self.config.inline && isInput && !allowInput;

      if (e.keyCode === 13 && isInput) {
        if (allowInput) {
          self.setDate(
            self._input.value,
            true,
            e.target === self.altInput ? self.config.altFormat : self.config.dateFormat
          );
          return e.target.blur();
        } else self.open();
      } else if (isCalendarElem(e.target) || allowKeydown || allowInlineKeydown) {
        var isTimeObj = !!self.timeContainer && self.timeContainer.contains(e.target);

        switch (e.keyCode) {
          case 13:
            if (isTimeObj) updateTime();
            else selectDate(e);
            break;

          case 27:
            e.preventDefault();
            focusAndCloseEsc();
            break;

          case 8:
          case 46:
            if (isInput && !self.config.allowInput) {
              e.preventDefault();
              self.clear();
            }

            break;

          case 37:
          case 39:
            if (!isTimeObj) {
              e.preventDefault();

              if (self.daysContainer !== undefined && (allowInput === false || isInView(document.activeElement))) {
                var _delta = e.keyCode === 39 ? 1 : -1;

                if (!e.ctrlKey) focusOnDay(undefined, _delta);
                else {
                  changeMonth(_delta);
                  focusOnDay(getFirstAvailableDay(1), 0);
                }
              }
            } else if (self.hourElement) self.hourElement.focus();

            break;

          case 38:
          case 40:
            e.preventDefault();
            var delta = e.keyCode === 40 ? 1 : -1;

            if (self.daysContainer && e.target.$i !== undefined) {
              if (e.ctrlKey) {
                changeYear(self.currentYear - delta);
                focusOnDay(getFirstAvailableDay(1), 0);
              } else if (!isTimeObj) focusOnDay(undefined, delta * 7);
            } else if (self.config.enableTime) {
              if (!isTimeObj && self.hourElement) self.hourElement.focus();
              updateTime(e);

              self._debouncedChange();
            }

            break;

          case 9:
            if (!isTimeObj) {
              self.element.focus();
              break;
            }

            var elems = [self.hourElement, self.minuteElement, self.secondElement, self.amPM].filter(function (x) {
              return x;
            });
            var i = elems.indexOf(e.target);

            if (i !== -1) {
              var target = elems[i + (e.shiftKey ? -1 : 1)];

              if (target !== undefined) {
                e.preventDefault();
                target.focus();
              } else {
                self.element.focus();
              }
            }

            break;

          default:
            break;
        }
      }

      if (self.amPM !== undefined && e.target === self.amPM) {
        switch (e.key) {
          case self.l10n.amPM[0].charAt(0):
          case self.l10n.amPM[0].charAt(0).toLowerCase():
            self.amPM.textContent = self.l10n.amPM[0];
            setHoursFromInputs();
            updateValue();
            break;

          case self.l10n.amPM[1].charAt(0):
          case self.l10n.amPM[1].charAt(0).toLowerCase():
            self.amPM.textContent = self.l10n.amPM[1];
            setHoursFromInputs();
            updateValue();
            break;
        }
      }

      triggerEvent('onKeyDown', e);
    }

    function onMouseOver(elem) {
      if (
        self.selectedDates.length !== 1 ||
        (elem && (!elem.classList.contains('flatpickr-day') || elem.classList.contains('disabled')))
      )
        return;
      var hoverDate = elem ? elem.dateObj.getTime() : self.days.firstElementChild.dateObj.getTime(),
        initialDate = self.parseDate(self.selectedDates[0], undefined, true).getTime(),
        rangeStartDate = Math.min(hoverDate, self.selectedDates[0].getTime()),
        rangeEndDate = Math.max(hoverDate, self.selectedDates[0].getTime()),
        lastDate = self.daysContainer.lastChild.lastChild.dateObj.getTime();
      var containsDisabled = false;
      var minRange = 0,
        maxRange = 0;

      for (var t = rangeStartDate; t < lastDate; t += duration.DAY) {
        if (!isEnabled(new Date(t), true)) {
          containsDisabled = containsDisabled || (t > rangeStartDate && t < rangeEndDate);
          if (t < initialDate && (!minRange || t > minRange)) minRange = t;
          else if (t > initialDate && (!maxRange || t < maxRange)) maxRange = t;
        }
      }

      for (var m = 0; m < self.config.showMonths; m++) {
        var month = self.daysContainer.children[m];
        var prevMonth = self.daysContainer.children[m - 1];

        var _loop = function _loop(i, l) {
          var dayElem = month.children[i],
            date = dayElem.dateObj;
          var timestamp = date.getTime();
          var outOfRange = (minRange > 0 && timestamp < minRange) || (maxRange > 0 && timestamp > maxRange);

          if (outOfRange) {
            dayElem.classList.add('notAllowed');
            ['inRange', 'startRange', 'endRange'].forEach(function (c) {
              dayElem.classList.remove(c);
            });
            return 'continue';
          } else if (containsDisabled && !outOfRange) return 'continue';

          ['startRange', 'inRange', 'endRange', 'notAllowed'].forEach(function (c) {
            dayElem.classList.remove(c);
          });

          if (elem !== undefined) {
            elem.classList.add(hoverDate < self.selectedDates[0].getTime() ? 'startRange' : 'endRange');

            if (month.contains(elem) || !(m > 0 && prevMonth && prevMonth.lastChild.dateObj.getTime() >= timestamp)) {
              if (initialDate < hoverDate && timestamp === initialDate) dayElem.classList.add('startRange');
              else if (initialDate > hoverDate && timestamp === initialDate) dayElem.classList.add('endRange');
              if (
                timestamp >= minRange &&
                (maxRange === 0 || timestamp <= maxRange) &&
                isBetween(timestamp, initialDate, hoverDate)
              )
                dayElem.classList.add('inRange');
            }
          }
        };

        for (var i = 0, l = month.children.length; i < l; i++) {
          var _ret = _loop(i, l);

          if (_ret === 'continue') continue;
        }
      }
    }

    function onResize() {
      if (self.isOpen && !self.config.static && !self.config.inline) positionCalendar();
    }

    function open(e, positionElement) {
      if (positionElement === void 0) {
        positionElement = self._positionElement;
      }

      if (self.isMobile === true) {
        if (e) {
          e.preventDefault();
          if (e.target) {
            e.target.blur();
          }
        }

        if (self.mobileInput !== undefined) {
          self.mobileInput.focus();
          self.mobileInput.click();
        }

        triggerEvent('onOpen');
        return;
      }

      if (self._input.disabled || self.config.inline) return;
      var wasOpen = self.isOpen;
      self.isOpen = true;

      if (!wasOpen) {
        self.calendarContainer.classList.add('open');

        self._input.classList.add('active');

        triggerEvent('onOpen');
        positionCalendar(positionElement);
      }

      if (self.config.enableTime === true && self.config.noCalendar === true) {
        if (self.selectedDates.length === 0) {
          self.setDate(self.config.minDate !== undefined ? new Date(self.config.minDate.getTime()) : new Date(), false);
          setDefaultHours();
          updateValue();
        }

        if (self.config.allowInput === false && (e === undefined || !self.timeContainer.contains(e.relatedTarget))) {
          setTimeout(function () {
            return self.hourElement.select();
          }, 50);
        }
      }
    }

    function minMaxDateSetter(type) {
      return function (date) {
        var dateObj = (self.config['_' + type + 'Date'] = self.parseDate(date, self.config.dateFormat));
        var inverseDateObj = self.config['_' + (type === 'min' ? 'max' : 'min') + 'Date'];

        if (dateObj !== undefined) {
          self[type === 'min' ? 'minDateHasTime' : 'maxDateHasTime'] =
            dateObj.getHours() > 0 || dateObj.getMinutes() > 0 || dateObj.getSeconds() > 0;
        }

        if (self.selectedDates) {
          self.selectedDates = self.selectedDates.filter(function (d) {
            return isEnabled(d);
          });
          if (!self.selectedDates.length && type === 'min') setHoursFromDate(dateObj);
          updateValue();
        }

        if (self.daysContainer) {
          redraw();
          if (dateObj !== undefined) self.currentYearElement[type] = dateObj.getFullYear().toString();
          else self.currentYearElement.removeAttribute(type);
          self.currentYearElement.disabled =
            !!inverseDateObj && dateObj !== undefined && inverseDateObj.getFullYear() === dateObj.getFullYear();
        }
      };
    }

    function parseConfig() {
      var boolOpts = [
        'wrap',
        'weekNumbers',
        'allowInput',
        'clickOpens',
        'time_24hr',
        'enableTime',
        'noCalendar',
        'altInput',
        'shorthandCurrentMonth',
        'inline',
        'static',
        'enableSeconds',
        'disableMobile',
      ];
      var userConfig = Object.assign({}, instanceConfig, JSON.parse(JSON.stringify(element.dataset || {})));
      var formats$$1 = {};
      self.config.parseDate = userConfig.parseDate;
      self.config.formatDate = userConfig.formatDate;
      Object.defineProperty(self.config, 'enable', {
        get: function get() {
          return self.config._enable;
        },
        set: function set(dates) {
          self.config._enable = parseDateRules(dates);
        },
      });
      Object.defineProperty(self.config, 'disable', {
        get: function get() {
          return self.config._disable;
        },
        set: function set(dates) {
          self.config._disable = parseDateRules(dates);
        },
      });
      var timeMode = userConfig.mode === 'time';

      if (!userConfig.dateFormat && (userConfig.enableTime || timeMode)) {
        formats$$1.dateFormat =
          userConfig.noCalendar || timeMode
            ? 'H:i' + (userConfig.enableSeconds ? ':S' : '')
            : flatpickr.defaultConfig.dateFormat + ' H:i' + (userConfig.enableSeconds ? ':S' : '');
      }

      if (userConfig.altInput && (userConfig.enableTime || timeMode) && !userConfig.altFormat) {
        formats$$1.altFormat =
          userConfig.noCalendar || timeMode
            ? 'h:i' + (userConfig.enableSeconds ? ':S K' : ' K')
            : flatpickr.defaultConfig.altFormat + (' h:i' + (userConfig.enableSeconds ? ':S' : '') + ' K');
      }

      Object.defineProperty(self.config, 'minDate', {
        get: function get() {
          return self.config._minDate;
        },
        set: minMaxDateSetter('min'),
      });
      Object.defineProperty(self.config, 'maxDate', {
        get: function get() {
          return self.config._maxDate;
        },
        set: minMaxDateSetter('max'),
      });

      var minMaxTimeSetter = function minMaxTimeSetter(type) {
        return function (val) {
          self.config[type === 'min' ? '_minTime' : '_maxTime'] = self.parseDate(val, 'H:i');
        };
      };

      Object.defineProperty(self.config, 'minTime', {
        get: function get() {
          return self.config._minTime;
        },
        set: minMaxTimeSetter('min'),
      });
      Object.defineProperty(self.config, 'maxTime', {
        get: function get() {
          return self.config._maxTime;
        },
        set: minMaxTimeSetter('max'),
      });

      if (userConfig.mode === 'time') {
        self.config.noCalendar = true;
        self.config.enableTime = true;
      }

      Object.assign(self.config, formats$$1, userConfig);

      for (var i = 0; i < boolOpts.length; i++) {
        self.config[boolOpts[i]] = self.config[boolOpts[i]] === true || self.config[boolOpts[i]] === 'true';
      }

      HOOKS.filter(function (hook) {
        return self.config[hook] !== undefined;
      }).forEach(function (hook) {
        self.config[hook] = arrayify(self.config[hook] || []).map(bindToInstance);
      });
      self.isMobile =
        !self.config.disableMobile &&
        !self.config.inline &&
        self.config.mode === 'single' &&
        !self.config.disable.length &&
        !self.config.enable.length &&
        !self.config.weekNumbers &&
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      for (var _i = 0; _i < self.config.plugins.length; _i++) {
        var pluginConf = self.config.plugins[_i](self) || {};

        for (var key in pluginConf) {
          if (HOOKS.indexOf(key) > -1) {
            self.config[key] = arrayify(pluginConf[key]).map(bindToInstance).concat(self.config[key]);
          } else if (typeof userConfig[key] === 'undefined') self.config[key] = pluginConf[key];
        }
      }

      triggerEvent('onParseConfig');
    }

    function setupLocale() {
      if (typeof self.config.locale !== 'object' && typeof flatpickr.l10ns[self.config.locale] === 'undefined')
        self.config.errorHandler(new Error('flatpickr: invalid locale ' + self.config.locale));
      self.l10n = Object.assign(
        {},
        flatpickr.l10ns.default,
        typeof self.config.locale === 'object'
          ? self.config.locale
          : self.config.locale !== 'default'
          ? flatpickr.l10ns[self.config.locale]
          : undefined
      );
      tokenRegex.K =
        '(' +
        self.l10n.amPM[0] +
        '|' +
        self.l10n.amPM[1] +
        '|' +
        self.l10n.amPM[0].toLowerCase() +
        '|' +
        self.l10n.amPM[1].toLowerCase() +
        ')';
      self.formatDate = createDateFormatter(self);
      self.parseDate = createDateParser({
        config: self.config,
        l10n: self.l10n,
      });
    }

    function positionCalendar(customPositionElement) {
      if (self.calendarContainer === undefined) return;
      triggerEvent('onPreCalendarPosition');
      var positionElement = customPositionElement || self._positionElement;
      var calendarHeight = Array.prototype.reduce.call(
          self.calendarContainer.children,
          function (acc, child) {
            return acc + child.offsetHeight;
          },
          0
        ),
        calendarWidth = self.calendarContainer.offsetWidth,
        configPos = self.config.position.split(' '),
        configPosVertical = configPos[0],
        configPosHorizontal = configPos.length > 1 ? configPos[1] : null,
        inputBounds = positionElement.getBoundingClientRect(),
        distanceFromBottom = window.innerHeight - inputBounds.bottom,
        showOnTop =
          configPosVertical === 'above' ||
          (configPosVertical !== 'below' && distanceFromBottom < calendarHeight && inputBounds.top > calendarHeight);
      var top =
        window.pageYOffset + inputBounds.top + (!showOnTop ? positionElement.offsetHeight + 2 : -calendarHeight - 2);
      toggleClass(self.calendarContainer, 'arrowTop', !showOnTop);
      toggleClass(self.calendarContainer, 'arrowBottom', showOnTop);
      if (self.config.inline) return;
      var left =
        window.pageXOffset +
        inputBounds.left -
        (configPosHorizontal != null && configPosHorizontal === 'center' ? (calendarWidth - inputBounds.width) / 2 : 0);
      var right = window.document.body.offsetWidth - inputBounds.right;
      var rightMost = left + calendarWidth > window.document.body.offsetWidth;
      toggleClass(self.calendarContainer, 'rightMost', rightMost);
      if (self.config.static) return;
      self.calendarContainer.style.top = top + 'px';

      if (!rightMost) {
        self.calendarContainer.style.left = left + 'px';
        self.calendarContainer.style.right = 'auto';
      } else {
        self.calendarContainer.style.left = 'auto';
        self.calendarContainer.style.right = right + 'px';
      }
    }

    function redraw() {
      if (self.config.noCalendar || self.isMobile) return;
      updateNavigationCurrentMonth();
      buildDays();
    }

    function focusAndClose() {
      self._input.focus();

      if (window.navigator.userAgent.indexOf('MSIE') !== -1 || navigator.msMaxTouchPoints !== undefined) {
        setTimeout(self.close, 0);
      } else {
        self.close();
      }
    }

    function focusAndCloseEsc() {
      self._input.focus();
      self.clear(false);

      if (window.navigator.userAgent.indexOf('MSIE') !== -1 || navigator.msMaxTouchPoints !== undefined) {
        setTimeout(self.close.bind('cancel'), 0);
      } else {
        self.close('cancel');
      }
      triggerEvent('onCancel');
    }

    function selectDate(e) {
      e.preventDefault();
      e.stopPropagation();

      var isSelectable = function isSelectable(day) {
        return (
          day.classList &&
          day.classList.contains('flatpickr-day') &&
          !day.classList.contains('disabled') &&
          !day.classList.contains('notAllowed')
        );
      };

      var t = findParent(e.target, isSelectable);
      if (t === undefined) return;
      var target = t;
      var selectedDate = (self.latestSelectedDateObj = new Date(target.dateObj.getTime()));
      var shouldChangeMonth =
        (selectedDate.getMonth() < self.currentMonth ||
          selectedDate.getMonth() > self.currentMonth + self.config.showMonths - 1) &&
        self.config.mode !== 'range';
      self.selectedDateElem = target;
      if (self.config.mode === 'single') self.selectedDates = [selectedDate];
      else if (self.config.mode === 'multiple') {
        var selectedIndex = isDateSelected(selectedDate);
        if (selectedIndex) self.selectedDates.splice(parseInt(selectedIndex), 1);
        else self.selectedDates.push(selectedDate);
      } else if (self.config.mode === 'range') {
        if (self.selectedDates.length === 2) self.clear(false);
        self.selectedDates.push(selectedDate);
        if (compareDates(selectedDate, self.selectedDates[0], true) !== 0)
          self.selectedDates.sort(function (a, b) {
            return a.getTime() - b.getTime();
          });
      }
      setHoursFromInputs();

      if (shouldChangeMonth) {
        var isNewYear = self.currentYear !== selectedDate.getFullYear();
        self.currentYear = selectedDate.getFullYear();
        self.currentMonth = selectedDate.getMonth();
        if (isNewYear) triggerEvent('onYearChange');
        triggerEvent('onMonthChange');
      }

      updateNavigationCurrentMonth();
      buildDays();
      updateValue();
      if (self.config.enableTime)
        setTimeout(function () {
          self.showTimeInput = true;
          return self.showTimeInput;
        }, 50);
      if (!shouldChangeMonth && self.config.mode !== 'range' && self.config.showMonths === 1) {
        if (self.config.mode === 'single') {
          if (self.selectedDateElem) {
            self.selectedDateElem.focus();
          }
        } else {
          focusOnDayElem(target);
        }
      } else {
        if (self.selectedDateElem) {
          self.selectedDateElem.focus();
        }
      }
      if (self.hourElement !== undefined)
        setTimeout(function () {
          return self.hourElement !== undefined && self.hourElement.select();
        }, 451);

      if (self.config.closeOnSelect) {
        var single = self.config.mode === 'single' && !self.config.enableTime;
        var range = self.config.mode === 'range' && self.selectedDates.length === 2 && !self.config.enableTime;

        if (single || range) {
          focusAndClose();
        }
      }

      triggerChange();
    }

    var CALLBACKS = {
      locale: [setupLocale, updateWeekdays],
      showMonths: [buildMonths, setCalendarWidth, buildWeekdays],
    };

    function set(option, value) {
      if (option !== null && typeof option === 'object') Object.assign(self.config, option);
      else {
        self.config[option] = value;
        if (CALLBACKS[option] !== undefined)
          CALLBACKS[option].forEach(function (x) {
            return x();
          });
        else if (HOOKS.indexOf(option) > -1) self.config[option] = arrayify(value);
      }
      self.redraw();
      jumpToDate();
      updateValue(false);
    }

    function setSelectedDate(inputDate, format) {
      var dates = [];
      if (inputDate instanceof Array)
        dates = inputDate.map(function (d) {
          return self.parseDate(d, format);
        });
      else if (inputDate instanceof Date || typeof inputDate === 'number') dates = [self.parseDate(inputDate, format)];
      else if (typeof inputDate === 'string') {
        switch (self.config.mode) {
          case 'single':
          case 'time':
            dates = [self.parseDate(inputDate, format)];
            break;

          case 'multiple':
            dates = inputDate.split(self.config.conjunction).map(function (date) {
              return self.parseDate(date, format);
            });
            break;

          case 'range':
            dates = inputDate.split(self.l10n.rangeSeparator).map(function (date) {
              return self.parseDate(date, format);
            });
            break;

          default:
            break;
        }
      } else self.config.errorHandler(new Error('Invalid date supplied: ' + JSON.stringify(inputDate)));
      self.selectedDates = dates.filter(function (d) {
        return d instanceof Date && isEnabled(d, false);
      });
      if (self.config.mode === 'range')
        self.selectedDates.sort(function (a, b) {
          return a.getTime() - b.getTime();
        });
    }

    function setDate(date, triggerChange, format) {
      if (triggerChange === void 0) {
        triggerChange = false;
      }

      if (format === void 0) {
        format = self.config.dateFormat;
      }

      if ((date !== 0 && !date) || (date instanceof Array && date.length === 0)) return self.clear(triggerChange);
      setSelectedDate(date, format);
      self.showTimeInput = self.selectedDates.length > 0;
      self.latestSelectedDateObj = self.selectedDates[0];
      self.redraw();
      jumpToDate();
      setHoursFromDate();
      updateValue(triggerChange);
      if (triggerChange) triggerEvent('onChange');
    }

    function parseDateRules(arr) {
      return arr
        .slice()
        .map(function (rule) {
          if (typeof rule === 'string' || typeof rule === 'number' || rule instanceof Date) {
            return self.parseDate(rule, undefined, true);
          } else if (rule && typeof rule === 'object' && rule.from && rule.to)
            return {
              from: self.parseDate(rule.from, undefined),
              to: self.parseDate(rule.to, undefined),
            };

          return rule;
        })
        .filter(function (x) {
          return x;
        });
    }

    function setupDates() {
      self.selectedDates = [];
      self.now = self.parseDate(self.config.now) || new Date();
      var preloadedDate =
        self.config.defaultDate ||
        ((self.input.nodeName === 'INPUT' || self.input.nodeName === 'TEXTAREA') &&
        self.input.placeholder &&
        self.input.value === self.input.placeholder
          ? null
          : self.input.value);
      if (preloadedDate) setSelectedDate(preloadedDate, self.config.dateFormat);
      var initialDate =
        self.selectedDates.length > 0
          ? self.selectedDates[0]
          : self.config.minDate && self.config.minDate.getTime() > self.now.getTime()
          ? self.config.minDate
          : self.config.maxDate && self.config.maxDate.getTime() < self.now.getTime()
          ? self.config.maxDate
          : self.now;
      self.currentYear = initialDate.getFullYear();
      self.currentMonth = initialDate.getMonth();
      if (self.selectedDates.length > 0) self.latestSelectedDateObj = self.selectedDates[0];
      if (self.config.minTime !== undefined) self.config.minTime = self.parseDate(self.config.minTime, 'H:i');
      if (self.config.maxTime !== undefined) self.config.maxTime = self.parseDate(self.config.maxTime, 'H:i');
      self.minDateHasTime =
        !!self.config.minDate &&
        (self.config.minDate.getHours() > 0 ||
          self.config.minDate.getMinutes() > 0 ||
          self.config.minDate.getSeconds() > 0);
      self.maxDateHasTime =
        !!self.config.maxDate &&
        (self.config.maxDate.getHours() > 0 ||
          self.config.maxDate.getMinutes() > 0 ||
          self.config.maxDate.getSeconds() > 0);
      Object.defineProperty(self, 'showTimeInput', {
        get: function get() {
          return self._showTimeInput;
        },
        set: function set(bool) {
          self._showTimeInput = bool;
          if (self.calendarContainer) toggleClass(self.calendarContainer, 'showTimeInput', bool);
          if (self.isOpen) {
            positionCalendar();
          }
        },
      });
    }

    function setupInputs() {
      self.input = self.config.wrap ? element.querySelector('[data-input]') : element;

      if (!self.input) {
        self.config.errorHandler(new Error('Invalid input element specified'));
        return;
      }

      self.input._type = self.input.type;
      self.input.type = 'text';
      self.input.classList.add('flatpickr-input');
      self._input = self.input;

      if (self.config.altInput) {
        self.altInput = createElement(self.input.nodeName, self.input.className + ' ' + self.config.altInputClass);
        self._input = self.altInput;
        self.altInput.placeholder = self.input.placeholder;
        self.altInput.disabled = self.input.disabled;
        self.altInput.required = self.input.required;
        self.altInput.tabIndex = self.input.tabIndex;
        self.altInput.type = 'text';
        self.input.setAttribute('type', 'hidden');
        if (!self.config.static && self.input.parentNode)
          self.input.parentNode.insertBefore(self.altInput, self.input.nextSibling);
      }

      if (!self.config.allowInput) self._input.setAttribute('readonly', 'readonly');
      self._positionElement = self.config.positionElement || self._input;
    }

    function setupMobile() {
      var inputType = self.config.enableTime ? (self.config.noCalendar ? 'time' : 'datetime-local') : 'date';
      self.mobileInput = createElement('input', self.input.className + ' flatpickr-mobile');
      self.mobileInput.step = self.input.getAttribute('step') || 'any';
      self.mobileInput.tabIndex = 1;
      self.mobileInput.type = inputType;
      self.mobileInput.disabled = self.input.disabled;
      self.mobileInput.required = self.input.required;
      self.mobileInput.placeholder = self.input.placeholder;
      self.mobileFormatStr =
        inputType === 'datetime-local' ? 'Y-m-d\\TH:i:S' : inputType === 'date' ? 'Y-m-d' : 'H:i:S';

      if (self.selectedDates.length > 0) {
        self.mobileInput.defaultValue = self.mobileInput.value = self.formatDate(
          self.selectedDates[0],
          self.mobileFormatStr
        );
      }

      if (self.config.minDate) self.mobileInput.min = self.formatDate(self.config.minDate, 'Y-m-d');
      if (self.config.maxDate) self.mobileInput.max = self.formatDate(self.config.maxDate, 'Y-m-d');
      self.input.type = 'hidden';
      if (self.altInput !== undefined) self.altInput.type = 'hidden';

      try {
        if (self.input.parentNode) self.input.parentNode.insertBefore(self.mobileInput, self.input.nextSibling);
      } catch (_a) {}

      bind(self.mobileInput, 'change', function (e) {
        self.setDate(e.target.value, false, self.mobileFormatStr);
        triggerEvent('onChange');
        triggerEvent('onClose');
      });
    }

    function toggle(e) {
      if (self.isOpen === true) return self.close();
      self.open(e);
    }

    function triggerEvent(event, data) {
      if (self.config === undefined) return;
      var hooks = self.config[event];

      if (hooks !== undefined && hooks.length > 0) {
        for (var i = 0; hooks[i] && i < hooks.length; i++) {
          hooks[i](self.selectedDates, self.input.value, self, data);
        }
      }

      if (event === 'onChange') {
        self.input.dispatchEvent(createEvent('change'));
        self.input.dispatchEvent(createEvent('input'));
      }
    }

    function createEvent(name) {
      var e = document.createEvent('Event');
      e.initEvent(name, true, true);
      return e;
    }

    function isDateSelected(date) {
      for (var i = 0; i < self.selectedDates.length; i++) {
        if (compareDates(self.selectedDates[i], date) === 0) return '' + i;
      }

      return false;
    }

    function isDateInRange(date) {
      if (self.config.mode !== 'range' || self.selectedDates.length < 2) return false;
      return compareDates(date, self.selectedDates[0]) >= 0 && compareDates(date, self.selectedDates[1]) <= 0;
    }

    function updateNavigationCurrentMonth() {
      if (self.config.noCalendar || self.isMobile || !self.monthNav) return;
      self.yearElements.forEach(function (yearElement, i) {
        var d = new Date(self.currentYear, self.currentMonth, 1);
        d.setMonth(self.currentMonth + i);
        self.monthElements[i].textContent =
          monthToStr(d.getMonth(), self.config.shorthandCurrentMonth, self.l10n) + ' ';
        yearElement.value = d.getFullYear().toString();
      });
      self._hidePrevMonthArrow =
        self.config.minDate !== undefined &&
        (self.currentYear === self.config.minDate.getFullYear()
          ? self.currentMonth <= self.config.minDate.getMonth()
          : self.currentYear < self.config.minDate.getFullYear());
      self._hideNextMonthArrow =
        self.config.maxDate !== undefined &&
        (self.currentYear === self.config.maxDate.getFullYear()
          ? self.currentMonth + 1 > self.config.maxDate.getMonth()
          : self.currentYear > self.config.maxDate.getFullYear());
    }

    function getDateStr(format) {
      return self.selectedDates
        .map(function (dObj) {
          return self.formatDate(dObj, format);
        })
        .filter(function (d, i, arr) {
          return self.config.mode !== 'range' || self.config.enableTime || arr.indexOf(d) === i;
        })
        .join(self.config.mode !== 'range' ? self.config.conjunction : self.l10n.rangeSeparator);
    }

    function updateValue(triggerChange) {
      if (triggerChange === void 0) {
        triggerChange = true;
      }

      if (self.selectedDates.length === 0) return self.clear(triggerChange);

      if (self.mobileInput !== undefined && self.mobileFormatStr) {
        self.mobileInput.value =
          self.latestSelectedDateObj !== undefined
            ? self.formatDate(self.latestSelectedDateObj, self.mobileFormatStr)
            : '';
      }

      self.input.value = getDateStr(self.config.dateFormat);

      if (self.altInput !== undefined) {
        self.altInput.value = getDateStr(self.config.altFormat);
      }

      if (triggerChange !== false) triggerEvent('onValueUpdate');
    }

    function onMonthNavClick(e) {
      e.preventDefault();
      var isPrevMonth = self.prevMonthNav.contains(e.target);
      var isNextMonth = self.nextMonthNav.contains(e.target);

      if (isPrevMonth || isNextMonth) {
        changeMonth(isPrevMonth ? -1 : 1);
      } else if (self.yearElements.indexOf(e.target) >= 0) {
        e.target.select();
      } else if (e.target.classList.contains('arrowUp')) {
        self.changeYear(self.currentYear + 1);
      } else if (e.target.classList.contains('arrowDown')) {
        self.changeYear(self.currentYear - 1);
      }
    }

    function timeWrapper(e) {
      e.preventDefault();
      var isKeyDown = e.type === 'keydown',
        input = e.target;

      if (self.amPM !== undefined && e.target === self.amPM) {
        self.amPM.textContent = self.l10n.amPM[int(self.amPM.textContent === self.l10n.amPM[0])];
      }

      var min = parseFloat(input.getAttribute('data-min')),
        max = parseFloat(input.getAttribute('data-max')),
        step = parseFloat(input.getAttribute('data-step')),
        curValue = parseInt(input.value, 10),
        delta = e.delta || (isKeyDown ? (e.which === 38 ? 1 : -1) : 0);
      var newValue = curValue + step * delta;

      if (typeof input.value !== 'undefined' && input.value.length === 2) {
        var isHourElem = input === self.hourElement,
          isMinuteElem = input === self.minuteElement;

        if (newValue < min) {
          newValue = max + newValue + int(!isHourElem) + (int(isHourElem) && int(!self.amPM));
          if (isMinuteElem) incrementNumInput(undefined, -1, self.hourElement);
        } else if (newValue > max) {
          newValue = input === self.hourElement ? newValue - max - int(!self.amPM) : min;
          if (isMinuteElem) incrementNumInput(undefined, 1, self.hourElement);
        }

        if (
          self.amPM &&
          isHourElem &&
          (step === 1 ? newValue + curValue === 23 : Math.abs(newValue - curValue) > step)
        ) {
          self.amPM.textContent = self.l10n.amPM[int(self.amPM.textContent === self.l10n.amPM[0])];
        }

        input.value = pad(newValue);
      }
    }

    init();
    return self;
  }

  function _flatpickr(nodeList, config) {
    var nodes = Array.prototype.slice.call(nodeList);
    var instances = [];

    for (var i = 0; i < nodes.length; i++) {
      var node = nodes[i];

      try {
        if (node.getAttribute('data-fp-omit') !== null) continue;

        if (node._flatpickr !== undefined) {
          node._flatpickr.destroy();

          node._flatpickr = undefined;
        }

        node._flatpickr = FlatpickrInstance(node, config || {});
        instances.push(node._flatpickr);
      } catch (e) {
        console.error(e);
      }
    }

    return instances.length === 1 ? instances[0] : instances;
  }

  if (typeof HTMLElement !== 'undefined') {
    HTMLCollection.prototype.flatpickr = NodeList.prototype.flatpickr = function (config) {
      return _flatpickr(this, config);
    };

    HTMLElement.prototype.flatpickr = function (config) {
      return _flatpickr([this], config);
    };
  }

  var flatpickr = function flatpickr(selector, config) {
    if (selector instanceof NodeList) return _flatpickr(selector, config);
    else if (typeof selector === 'string') return _flatpickr(window.document.querySelectorAll(selector), config);
    return _flatpickr([selector], config);
  };

  flatpickr.defaultConfig = defaults;
  flatpickr.l10ns = {
    en: Object.assign({}, english),
    default: Object.assign({}, english),
  };

  flatpickr.localize = function (l10n) {
    flatpickr.l10ns.default = Object.assign({}, flatpickr.l10ns.default, l10n);
  };

  flatpickr.setDefaults = function (config) {
    flatpickr.defaultConfig = Object.assign({}, flatpickr.defaultConfig, config);
  };

  flatpickr.parseDate = createDateParser({});
  flatpickr.formatDate = createDateFormatter({});
  flatpickr.compareDates = compareDates;

  if (typeof jQuery !== 'undefined') {
    jQuery.fn.flatpickr = function (config) {
      return _flatpickr(this, config);
    };
  }

  Date.prototype.fp_incr = function (days) {
    return new Date(
      this.getFullYear(),
      this.getMonth(),
      this.getDate() + (typeof days === 'string' ? parseInt(days, 10) : days)
    );
  };

  if (typeof window !== 'undefined') {
    window.flatpickr = flatpickr;
  }

  return flatpickr;
});
/* jshint ignore:end */
