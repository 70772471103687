export default {
  init() {
    this.initRadioButtons();
    this.initTemplate();
    this.initColumnSearch();
    this.initAddUtmDimensionButton();
    this.initDeleteUtmDimensionButtons();
    this.initOrderNumberValidation(null);
  },
  initTemplate() {
    $('#choose-template').on('change', data => {
      if (data.target.value === 'campaign') {
        this.hidePlacements();
      } else {
        this.showPlacements();
      }
    });
  },
  hidePlacements() {
    $('.placements-block').addClass('d-none');
    $('.placements-block .form-check-input').each((index, item) => {
      const $item = $(item);

      if ($item.prop('checked')) {
        $item.prop('checked', false);
        $item.trigger('change');
      }
    });
  },
  showPlacements() {
    $('.placements-block').removeClass('d-none');
  },
  initColumnSearch() {
    $('.j-column-search').on('input', function (e) {
      const blockPosition = $(e.target).attr('data-block-position');
      const columns = $(`table.edit-template-table[data-block-position="${blockPosition}"] tbody tr`);
      for (const column of columns) {
        if ($(column).attr('data-column-display-name').toLowerCase().includes(e.target.value.toLowerCase().trim())) {
          $(column).removeClass('d-none');
        } else {
          $(column).addClass('d-none');
        }
      }
    });
  },
  initRadioButtons() {
    $('.j-template-table-block').on('change', function (e) {
      const $target = $(e.target);
      const parentTable = $target.closest('table');
      const orderValues = [];

      parentTable.find('.j-order-input:not(.d-none)').each((index, item) => {
        const val = $(item).prop('value');

        if (val) {
          orderValues.push(parseInt(val));
        }
      });

      const maxOrderNumber = orderValues.length ? Math.max(...orderValues) : 0;

      if (e.target.type === 'checkbox') {
        if (!$target.closest('.j-selected-checkbox').length) {
          const tr = $target.closest('tr');
          const input = tr.find('.j-order-input');

          if ($target.is(':checked')) {
            tr.find('.j-selected-checkbox').removeClass('d-none');
            input.val(maxOrderNumber + 1);
            input.removeClass('is-invalid');
            input.removeClass('d-none');
            input.prop('required', true);
          } else {
            tr.find('.j-selected-checkbox').addClass('d-none');
            tr.find('.invalid-feedback').tooltip('hide');
            input.addClass('d-none');
            input.removeClass('is-invalid');
            input.prop('required', false);
          }

          const btn = $(document).find('[type="submit"]');

          if ($('.j-order-input.is-invalid').length) {
            btn.prop('disabled', true);
          } else {
            btn.prop('disabled', false);
          }
        }
      }
    });
  },
  initAddUtmDimensionButton() {
    $('#j-add-utm-dimension-button').on('click', e => {
      e.preventDefault();
      const $target = $(e.target);
      const card = $target.closest('.card-body');
      const utmTable = card.find('[data-table="utm-table"]');
      const orderValues = [];

      utmTable.find('.j-order-input:not(.d-none)').each((index, item) => {
        const val = $(item).prop('value');

        if (val) {
          orderValues.push(parseInt(val));
        }
      });

      const maxOrderNumber = Math.max(...orderValues);
      const houseId = $('.j-edit-template-container').attr('data-house-id');
      const templateType = $('.j-edit-template-container').attr('data-template-type');

      $.ajax({ method: 'GET', url: `/template/${templateType}/utm_row/${maxOrderNumber + 1}/${houseId}/new` })
        .done(htmlRow => {
          $('.j-utm-rows-container').append(htmlRow);
          this.initDeleteUtmDimensionButtons();
          setTimeout(() => {
            this.initOrderNumberValidation($(`[data-row-index="${maxOrderNumber + 1}"]`).find('.j-order-input'));
          }, 300);
        })
        .fail(err => {
          console.error(err);
        });
    });
  },
  initDeleteUtmDimensionButtons() {
    $('.j-delete-utm-row-button')
      .off('click')
      .on('click', e => {
        const $target = $(e.target);
        $target.closest('.j-utm-row').remove();
      });
  },
  initOrderNumberValidation(item) {
    const handler = e => {
      const $target = $(e.target);
      const val = parseInt($target.prop('value'));
      const parentTable = $target.closest('table');
      let orderValues = [];
      const orderInputs = parentTable.find('.j-order-input:not(.d-none)');

      orderInputs.not($target).each((index, item) => {
        const val = $(item).prop('value');
        orderValues.push(parseInt(val));
      });

      const btn = $(document).find('[type="submit"]');

      if (orderValues.indexOf(val) === -1) {
        $target.removeClass('is-invalid');
        $target.closest('td').find('.invalid-feedback').tooltip('hide');
      } else {
        $target.addClass('is-invalid');
        $target.closest('td').find('.invalid-feedback').tooltip('show');
      }

      if ($('.j-order-input.is-invalid').length) {
        btn.prop('disabled', true);
      } else {
        btn.prop('disabled', false);
      }

      orderValues = [];

      orderInputs.each((index, item) => {
        const val = $(item).prop('value');
        orderValues.push(parseInt(val));
      });

      orderInputs.each((index, item) => {
        const val = parseInt($(item).prop('value'));
        const filtered = orderValues.filter(x => x === val).length;

        if (filtered < 2) {
          $(item).removeClass('is-invalid');
          $(item).closest('td').find('.invalid-feedback').tooltip('hide');
        }
      });
    };

    if (item) {
      item.on('change blur', handler);
    } else {
      $('.j-order-input').on('change blur', handler);
    }
  },
};
