export default {
  init() {
    this.initHandlers();
  },

  initHandlers() {
    $('#activateUserPopup').on('show.bs.modal', function (event) {
      const link = $(event.relatedTarget);
      const modal = $(this);
      modal.find('[data-activate="true"]').attr('href', link.attr('href'));
    });

    $('#deactivateUserPopup').on('show.bs.modal', function (event) {
      const link = $(event.relatedTarget);
      const modal = $(this);
      modal.find('[data-activate="false"]').attr('href', link.attr('href'));
    });

    $('#removePopup').on('show.bs.modal', function (event) {
      const button = $(event.relatedTarget);
      const modal = $(this);
      modal.find('[data-delete="true"]').attr('href', button.attr('data-href'));
      modal
        .find('.modal-title')
        .text(
          'You will delete this user. Warning: this will also delete all the works created by this user, are you sure?'
        );
    });
  },
};
