import tableNotification from '../components/tableNotification';
import { ADS_TABLE_NAME, TABLE_TYPE_PLACEMENT, TABLE_TYPE_UTM } from '../constants';

export default class RemoveRow {
  init() {
    this.initHandlers();
  }

  initHandlers() {
    const removePopup = $('#removePopup');
    this.computeRemoveButtonDisableStatus();

    removePopup.on('show.bs.modal', event => {
      const originTargetIndex = $(event.relatedTarget).closest('tr').attr('data-local-index');
      const originTableName = $(event.relatedTarget).closest('.card').find('h3').text();
      const originTableType = $(event.relatedTarget).closest('.card').attr('data-type');
      const utm = $('.j-utm-container');
      let trListDataToRemove = [];
      let containers = [];

      if (originTableType === TABLE_TYPE_UTM) {
        const adsTitle = $('.j-placement-container h3').filter(
          (_, item) => $(item).text().trim().toLowerCase() === ADS_TABLE_NAME
        );
        const adsContainer = adsTitle.closest('.j-placement-container');
        containers = [...utm, ...adsContainer];
      }

      if (originTableType === TABLE_TYPE_PLACEMENT) {
        const placement = $(event.relatedTarget).closest('.j-placement-container');
        if (originTableName.trim().toLowerCase() === ADS_TABLE_NAME) {
          containers = [...utm, ...placement];
        } else {
          containers = [...placement];
        }
      }

      const filteredContainers = containers.filter(container => $(container).find('tbody tr').length > 1);

      if (filteredContainers.length) {
        trListDataToRemove = this.getTableRowDataToRemove(filteredContainers, originTargetIndex);
      }

      if (trListDataToRemove && trListDataToRemove.length !== 0) {
        const modal = $(event.target);
        modal.find('[data-delete="true"]').attr('data-table-rows-info', JSON.stringify(trListDataToRemove));
      }
    });

    removePopup.on('click', e => {
      const $target = $(e.target).closest('[data-delete]');
      $('.j-delete-row, .j-duplicate-row, .j-new-row, .j-generate-result, .j-export-work-button').addClass('disabled');
      const attrTableRowsInfo = $target.attr('data-table-rows-info');

      if ($target.length && attrTableRowsInfo) {
        const trListDataToRemove = JSON.parse(attrTableRowsInfo);

        Promise.all(trListDataToRemove.map(trData => this.removeTableRow(trData, $target))).then(() => {
          $('.j-delete-row, .j-duplicate-row, .j-new-row, .j-generate-result, .j-export-work-button').removeClass(
            'disabled'
          );
          this.computeRemoveButtonDisableStatus();
        });
      }
    });
  }

  async removeTableRow(trData, target) {
    const { rowId, containerId } = trData;
    const container = $(`[data-container-id=${containerId}]`);

    if (rowId) {
      target.removeAttr('data-table-rows-info');

      const originRow = container.find(`tr[data-row-id=${rowId}]`);
      const table = container.find('table');
      await this.sendRequestRemove({ row_id: rowId }, containerId, originRow, table);
      container.find('table').trigger('removeItem');
    } else {
      $('[data-prepare-to-remove=true]').remove();
    }
  }

  getTableRowDataToRemove(containers, originTargetIndex) {
    const trListDataToRemove = [];
    containers.forEach(container => {
      const $trListToRemove = $(container).find(`tr[data-local-index="${originTargetIndex}"]`);

      $trListToRemove.each((_, tr) => {
        const $tr = $(tr);
        const rowId = $tr.attr('data-row-id');

        if (rowId) {
          trListDataToRemove.push({
            rowId: $tr.attr('data-row-id'),
            containerId: $tr.closest('.j-container').attr('data-container-id'),
          });
        } else {
          $tr.attr('data-prepare-to-remove', true);
        }
      });
    });

    return trListDataToRemove;
  }

  async sendRequestRemove(sendingData, containerId, tr, table) {
    const builderId = $('.j-campaign-placement-editable-container').attr('data-builder-id');
    const templateType = table.attr('data-block');
    await $.ajax({
      method: 'POST',
      data: JSON.stringify(sendingData),
      url: `/builder/${builderId}/${templateType}/remove`,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    })
      .then(() => {
        tr.remove();
      })
      .catch(({ responseJSON }) => {
        tableNotification.showMessageByTime(containerId, responseJSON.error, 'error', 2000);
      });
  }

  computeRemoveButtonDisableStatus() {
    $('.j-placement-container, .j-utm-container').each((_, container) => {
      const $tableRows = $(container).find('tbody tr');

      if ($tableRows.length === 1) {
        $tableRows.find('.j-delete-row').addClass('disabled').removeAttr('data-bs-toggle');
      } else {
        $tableRows.find('.j-delete-row').removeClass('disabled').attr('data-bs-toggle', 'modal');
      }
    });
  }
}
