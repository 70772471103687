import { Tooltip } from 'bootstrap';
import isTouch from './isTouch';
import { collectInputsAndAddListener } from '../utils/use_input_cleaning_pattern';

export default {
  init() {
    isTouch.init();

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });

    collectInputsAndAddListener();
  },
};
