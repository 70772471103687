export default {
  isTouch: null,
  init() {
    this.body = $('body');
    this.isTouchHadler();
  },
  isTouchHadler() {
    var self = this;

    if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
      self.body.addClass('touch');
      self.isTouch = true;
    } else {
      self.body.addClass('no-touch');
      self.isTouch = false;
    }
  },
};
