import AlertNotification from '../components/AlertNotification';
import { addColumnValue } from '../utils/add_column_value';
import { forceInputUppercase } from '../utils/force_input_uppercase';
import { updateFileInput } from '../utils/update_file_button';

export default {
  init() {
    this.table = $('.j-table');
    this.initHandlers();
    this.initValidationLocal();
    this.deleteOrRestoreParentAction();
    window.deleteRow = this.deleteRow.bind(this);
  },

  initHandlers() {
    this.initGlobalSubmitHandler();

    $('#add_new_col').on('click', function (e) {
      const formEl = $(e.target).attr('form');
      const $form = $(`#${formEl}`);

      if (!$form.hasClass('was-validated')) {
        $form.addClass('was-validated');
      }
    });

    $('.new_short_val').on('keyup', forceInputUppercase);

    updateFileInput();

    this.table.on('input', function (e) {
      if (e.target.nodeName === 'INPUT') {
        $(e.target).parent().parent().find('.j-update-btn').removeClass('d-none');
      }
    });
  },

  initGlobalSubmitHandler() {
    $('#form-column-submit-btn').on('click', e => {
      e.preventDefault();
      const formEl = $(e.target).attr('form');
      const $form = $(`#${formEl}`);
      const isValid = $form[0].checkValidity();

      if (!$form.hasClass('was-validated')) {
        $form.addClass('was-validated');
      }

      if (isValid) {
        const shortValues = $form
          .find('.new_short_val')
          .map((i, el) => el.value)
          .get();

        const duplicates = shortValues.filter((item, index) => shortValues.indexOf(item) !== index);
        if (duplicates.length) {
          this.showError('Duplicate short values are not allowed: ' + duplicates.join(', '));
          return;
        }

        $form.submit();
      } else {
        $form[0].reportValidity();
      }
    });
  },

  deleteRow(btn) {
    const row = btn.parentNode.parentNode;
    row.parentNode.removeChild(row);
  },

  showError(text) {
    AlertNotification.AppendHtml('error', text, this.table.closest('.card'), true);
  },

  removeError() {
    AlertNotification.RemoveHtml(this.table.closest('.card'), true);
  },

  initValidationLocal() {
    $('.j-form-column-local').on('submit', e => {
      const $form = $(e.target);
      const isValid = $form[0].checkValidity();

      if (isValid) {
        const ln = this.table.find('tbody tr').length;
        addColumnValue(this.table.find('tbody'), ln);
      }

      e.preventDefault();
      return false;
    });
  },

  deleteOrRestoreParentAction() {
    const removeParentBtn = Array.from(document.querySelectorAll('[id^=remove_]'));
    const restoreParentBtn = Array.from(document.querySelectorAll('[id^=restore_]'));
    const buttons = [...removeParentBtn, ...restoreParentBtn];

    if (buttons.length) {
      buttons.forEach(item => {
        item.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          const itemId = e.target.id;
          const answer = itemId.includes('remove') ? confirm('Are you sure you want to delete?') : true;
          const url = $(e.target).attr('data-path');

          if (answer) {
            $.ajax({
              type: 'POST',
              url: url,
            }).done(() => {
              window.location.reload();
            });
          }
        });
      });
    }
  },
};
