export function reIndexTableRows() {
  // Reset global index
  $('.j-campaign-placement-editable-container tbody tr').each((index, item) => {
    $(item).attr('data-index', index);
  });

  // Reset local index
  $('.j-container').each((_, container) => {
    $(container)
      .find('tbody tr')
      .each((localIndex, item) => {
        $(item).attr('data-local-index', localIndex);
      });
  });
}
