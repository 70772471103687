export default {
  init() {
    this.initHandlers();
  },

  initHandlers() {
    $('#removePopup').on('show.bs.modal', function (event) {
      const button = $(event.relatedTarget);
      const modal = $(this);
      modal.find('[data-delete="true"]').attr('href', button.attr('data-href'));
      modal
        .find('.modal-title')
        .text(
          "You will delete this house, are you sure? Warning: it's impossible to delete a house if some templates are associated to it."
        );
    });
  },
};
