export function useInputCleaningPattern(event) {
  const { target } = event;
  const pattern = new RegExp(target.dataset.cleaningPattern);
  target.value = target.value.replace(pattern, '');
}

export function collectInputsAndAddListener() {
  const inputs = document.querySelectorAll('[data-cleaning-pattern]');

  inputs.forEach(item => {
    item.addEventListener('input', event => {
      useInputCleaningPattern(event);
    });
    item.addEventListener('paste', event => {
      setTimeout(() => {
        useInputCleaningPattern(event);
      }, 0);
    });
  });
}
