export default {
  showMessage(containerId, text, state) {
    const notificationBlock = $(`[data-container-id=${containerId}]`).find('.notify-text');
    notificationBlock.text(text);
    this[containerId] = '';
    this.addState(notificationBlock, state);
  },

  showMessageByTime(containerId, text, state, time) {
    const notificationBlock = $(`[data-container-id=${containerId}]`).find('.notify-text');
    notificationBlock.text(text);
    this.addState(notificationBlock, state);
    clearTimeout(this[containerId]);
    this[containerId] = setTimeout(() => {
      notificationBlock.text('');
      notificationBlock.attr('data-state', '');
    }, time);
  },

  addState(notificationBlock, state) {
    if (state === 'success') {
      notificationBlock.attr('data-state', 'success');
    } else if (state === 'error') {
      notificationBlock.attr('data-state', 'error');
    } else {
      notificationBlock.attr('data-state', 'notification');
    }
  },
};
