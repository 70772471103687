import touchModule from './isTouch';

export default class FullPageGradient {
  constructor() {}

  init() {
    if (!touchModule.isTouch) {
      this.initFullPage();
      this.initAnimation();
    }
  }

  initFullPage() {
    const _wH = $(window).innerHeight();
    const _wW = $(window).innerWidth();

    $('#full-page-gradient').css({
      width: _wW,
      height: _wH,
    });
  }

  initAnimation() {
    const self = this;
    const dpr = window.devicePixelRatio || 1;
    // Get the size of the canvas in CSS pixels.

    this.canvas = document.getElementById('full-page-gradient');
    const rect = this.canvas.getBoundingClientRect();
    // Give the canvas pixel dimensions of their CSS
    // size * the device pixel ratio.
    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.$ = this.canvas.getContext('2d');
    let time = false;
    let circles = [];
    this.loop = () => {
      const now = new Date().getTime();
      const d = now - (time || now);
      time = now;

      this.$.clearRect(0, 0, this.canvas.width, this.canvas.height);

      // draw
      for (let i = 0; i < circles.length; i++) {
        circles[i].update(d);
        circles[i].draw();
      }
      requestAnimationFrame(this.loop);
    };

    // circle
    const circle = function (options) {
      const circle = this;
      circle.settings = {
        x: 0,
        y: 0,
        radius: 10,
        orientation: 0,
        vector: { x: 0, y: 0 },
        speed: 1.5,
        color: { red: 0, green: 0, blue: 0, alpha: 1 },
      };
      const newsettings = {};

      for (let attrname in circle.settings) {
        if (circle.settings.hasOwnProperty(attrname)) {
          newsettings[attrname] = circle.settings[attrname];
        }
      }

      for (let attrname in options) {
        if (options.hasOwnProperty(attrname)) {
          newsettings[attrname] = options[attrname];
        }
      }
      circle.settings = newsettings;

      // update
      circle.update = d => {
        // update position
        circle.settings.x += circle.settings.vector.x * circle.settings.speed * d;
        circle.settings.y += circle.settings.vector.y * circle.settings.speed * d;

        if (
          (circle.settings.x < 0 && circle.settings.vector.x < 0) ||
          (circle.settings.x > self.canvas.width && circle.settings.vector.x > 0)
        ) {
          circle.settings.vector.x = circle.settings.vector.x * -1;
        }
        if (
          (circle.settings.y < 0 && circle.settings.vector.y < 0) ||
          (circle.settings.y > self.canvas.height && circle.settings.vector.y > 0)
        ) {
          circle.settings.vector.y = circle.settings.vector.y * -1;
        }
      };

      // draw
      circle.draw = () => {
        // gradient
        const gradient = self.$.createRadialGradient(
          circle.settings.x,
          circle.settings.y,
          circle.settings.radius / 50,
          circle.settings.x,
          circle.settings.y,
          circle.settings.radius
        );

        //play with the below two color stop values (the .2 and .3) to change circle definition - should be a value between 0 and 1. I chose to use float values bc I like the 'orbs' look.  Hint: If you make the first value a 0 and the second a 1, you'll achieve the smooth gradient effect.

        gradient.addColorStop(
          0.2,
          'rgba(' +
            circle.settings.color.red +
            ', ' +
            circle.settings.color.green +
            ', ' +
            circle.settings.color.blue +
            ', ' +
            circle.settings.color.alpha +
            ')'
        );
        gradient.addColorStop(
          0.3,
          'rgba(' +
            circle.settings.color.red +
            ', ' +
            circle.settings.color.green +
            ', ' +
            circle.settings.color.blue +
            ', ' +
            circle.settings.color.alpha / 20 +
            ')'
        );
        self.$.fillStyle = gradient;
        // draw
        self.$.beginPath();
        self.$.arc(circle.settings.x, circle.settings.y, circle.settings.radius, 0, 2 * Math.PI, false);
        self.$.fill();
      };
    };

    // create new
    const newcircles = () => {
      // remove old
      circles = [];
      // create  new circles
      for (let i = 0; i < 10; i++) {
        const _red = 120 + Math.floor(Math.random() * 155);
        const _green = 120 + Math.floor(Math.random() * 155);
        const _blue = 120 + Math.floor(Math.random() * 155);
        const _alpha = 0.1 + Math.random();

        // console.log('rgba(' + _red + ',' + _green + ',' + _blue + ',' + _alpha + ')');

        let newcircle = new circle({
          x: Math.floor(Math.random() * this.canvas.width),
          y: Math.floor(Math.random() * this.canvas.height),
          radius: Math.floor(Math.random() * this.canvas.width),
          orientation: Math.floor(Math.random() * 360),
          vector: {
            x: Math.random() / 40,
            y: Math.random() / 40,
          },
          speed: Math.random(),
          color: {
            red: _red,
            green: _green,
            blue: _blue,
            alpha: _alpha,
          },
        });

        // add new to array
        circles.push(newcircle);
      }
    };

    // generate new
    // window.addEventListener('click', newcircles, false);
    // window.addEventListener('touchend', newcircles, false);
    newcircles();
    // first frame
    // requestAnimationFrame(this.loop);
    this.loop();
  }

  resize() {
    if (!touchModule.isTouch) {
      const _wH = $(window).innerHeight();
      const _wW = $(window).innerWidth();

      this.$.clearRect(0, 0, this.canvas.width, this.canvas.height);

      $('#full-page-gradient').css({
        width: _wW,
        height: _wH,
      });
    }
  }
}
