import selectizeConfig from './utils/selectize_config';

require('../css/style.less');
global.selectize = require('@selectize/selectize');
global.jQuery = require('jquery');
global.$ = global.jQuery;

require('bootstrap');
if (['login_page', 'reset_page', 'set_password_page', 'error_page'].indexOf(global.vars.page) === -1) {
  require('admin-lte');
}
global.flatpickr = require('./libs/flatpickr');

import GENERAL from './components/General';
import BUILDERNEWPAGE from './pages/builder_new_page';
import BUILDERLISTPAGE from './pages/builder_list_page';
import EDITTEMPLATEPAGE from './pages/edit_template_page';
import EDITCOLUMNPAGE from './pages/edit_column_page';
import BUILDERSHOWPAGE from './pages/builder_show_page';
import CREATECOLUMNPAGE from './pages/new_column_page';
import COLUMNLISTPAGE from './pages/column_list_page';
import EDITDEPENDENCIESPAGE from './pages/edit_dependencies_page';
import EDITPAIRSPAGE from './pages/edit_pairs_page';
import EDITPAIRDDEPENDENCIESPAGE from './pages/edit_pairs_dependencies_page';
import LOGINPAGE from './pages/login_page';
import RESETPAGE from './pages/reset_page';
import SETPASSWORDPAGE from './pages/set_password_page';
import NOTFOUNDPAGE from './pages/error_page';
import TEMPLATESLISTPAGE from './pages/templates_list_page';
import COMPANYLISTUSER from './pages/company_users_list';
import HOUSELISTPAGE from './pages/house_list_page';
import COMPANYLISTPAGE from './pages/company_list_page';
import TEMPLATESENDEMAILPAGE from './pages/template_send_email_page';
import CHANNELLISTPAGE from './pages/channel_list_page';

if (dev) {
  if (module.hot) {
    module.hot.accept();
  }
}

let init = function () {};
let resize = function () {};

switch (global.vars.page) {
  case 'builder_list_page':
    init = BUILDERLISTPAGE.init.bind(BUILDERLISTPAGE);
    break;
  case 'builder_new_page':
    init = BUILDERNEWPAGE.init.bind(BUILDERNEWPAGE);
    break;
  case 'edit_template_page':
    init = EDITTEMPLATEPAGE.init.bind(EDITTEMPLATEPAGE);
    break;
  case 'edit_column_page':
    init = EDITCOLUMNPAGE.init.bind(EDITCOLUMNPAGE);
    break;
  case 'builder_show_page':
    init = BUILDERSHOWPAGE.init.bind(BUILDERSHOWPAGE);
    break;
  case 'new_column_page':
    init = CREATECOLUMNPAGE.init.bind(CREATECOLUMNPAGE);
    break;
  case 'column_list_page':
    init = COLUMNLISTPAGE.init.bind(COLUMNLISTPAGE);
    break;
  case 'edit_dependencies_page':
    init = EDITDEPENDENCIESPAGE.init.bind(EDITDEPENDENCIESPAGE);
    break;
  case 'edit_pairs_page':
    init = EDITPAIRSPAGE.init.bind(EDITPAIRSPAGE);
    break;
  case 'edit_pairs_dependencies_page':
    init = EDITPAIRDDEPENDENCIESPAGE.init.bind(EDITPAIRDDEPENDENCIESPAGE);
    break;
  case 'login_page':
    init = LOGINPAGE.init.bind(LOGINPAGE);
    resize = LOGINPAGE.resize.bind(LOGINPAGE);
    break;
  case 'reset_page':
    init = RESETPAGE.init.bind(RESETPAGE);
    resize = RESETPAGE.resize.bind(RESETPAGE);
    break;
  case 'set_password_page':
    init = SETPASSWORDPAGE.init.bind(SETPASSWORDPAGE);
    resize = SETPASSWORDPAGE.resize.bind(SETPASSWORDPAGE);
    break;
  case 'error_page':
    init = NOTFOUNDPAGE.init.bind(NOTFOUNDPAGE);
    resize = NOTFOUNDPAGE.resize.bind(NOTFOUNDPAGE);
    break;
  case 'templates_list_page':
    init = TEMPLATESLISTPAGE.init.bind(TEMPLATESLISTPAGE);
    break;
  case 'user_list_page':
    init = COMPANYLISTUSER.init.bind(COMPANYLISTUSER);
    break;
  case 'house_list_page':
    init = HOUSELISTPAGE.init.bind(HOUSELISTPAGE);
    break;
  case 'company_list_page':
    init = COMPANYLISTPAGE.init.bind(COMPANYLISTPAGE);
    break;
  case 'template_send_email_page':
    init = TEMPLATESENDEMAILPAGE.init.bind(TEMPLATESENDEMAILPAGE);
    break;
  case 'channel_list_page':
    init = CHANNELLISTPAGE.init.bind(CHANNELLISTPAGE);
    break;
  default:
    init = () => {};
}

$(document).ready(GENERAL.init(), init());
$(document).ready(() => {
  $('[data-selectize-init]').each((index, item) => {
    const isDisabled = $(item).prop('disabled');
    // const hasEnoughOptions = $(item).find('option').length > 9;

    if (!isDisabled) {
      $(item).removeClass('form-control').selectize(selectizeConfig);
      $(item).addClass('form-control');
      $(item).parents('.table-responsive').addClass('has-selectize');
    }
  });
});
$(window).resize(() => {
  resize();
});
