import { forceInputUppercase } from './force_input_uppercase';

export function addColumnValue(element, ln) {
  element.append(`
    <tr>
      <td>
        <input class="new_val form-control" type="text"
          name="new_val[${ln}]"
          required
          maxlength="200"
          value="${$('#new_val').val()}"
        >
      </td>
      <td>
        <input class="new_short_val form-control" type="text"
          name="short_val[${ln}]"
          required
          data-cleaning-pattern="[^a-zA-Z0-9-.{}]"
          pattern="^[A-Z0-9\\-.\\{\\}]+$"
          maxlength="50"
          value="${$('#short_val').val()}"
        >
      </td>
      <td>
        <input class="new_position form-control" type="number"
          name="position[${ln}]"
          required
          min="0"
          value="${$('#position').val()}"
        >
      </td>
      <td>
        <a
          title="Delete row"
          href="#"
          class="btn btn-outline-danger"
          onclick="window.deleteRow(this)"
        >
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </td>
    </tr>
  `);
  $('#new_val').val('');
  $('#short_val').val('');
  $('#position').val('');
  $('.new_short_val').off('keyup').on('keyup', forceInputUppercase);
}
