export function updateFileInput() {
  const $input = $('#upload-column-file');
  $input.on('change', () => {
    if ($input.val() === '') {
      return;
    }

    const file = $input[0].files[0].name;
    $input.prev('label').text(file);
    $('#upload-column-file-submit').removeClass('hide');
    $('#upload-column-file-submit').parent().addClass('btn-group');
  });
}
