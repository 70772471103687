import AlertNotification from '../components/AlertNotification';

export default {
  init() {
    this.initHandlers();
  },

  initHandlers() {
    const $form = $('.j-form-edit-dependencies');
    $('.j-form-edit-dependencies').on('change', e => {
      const switcher = $(e.target).closest('.j-switcher-empty');
      const tr = $(e.target).closest('tr');
      const select = $(e.target).closest('select');
      if (switcher.length) {
        if (switcher.find('input:checked').length) {
          this.deselectItems(tr);
        } else {
          this.deselectEmpty(tr);
        }
      } else if (select.length) {
        this.deselectCheckbox(tr);
      }
    });

    $form.on('submit', e => {
      e.preventDefault();
      $form.find("option:contains('__none_selected')").each((index, item) => {
        if (!$(item).closest('tr').find('.j-switcher-empty input').is(':checked')) {
          $(item).prop('selected', true);
          $(item).attr('selected', 'selected');
        }
      });

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: JSON.stringify($form.serializeArray()),
        dataType: 'json',
        success(data) {
          AlertNotification.RemoveHtml($form.find('.card'), true);
          document.location.href = `/${data.data.link}`;
        },
        error(err) {
          AlertNotification.AppendHtml('error', err.responseJSON.error, $form.find('.card'), true);
        },
      });
    });
  },

  deselectItems(tr) {
    tr.find('option:selected').each((index, item) => {
      $(item).prop('selected', false);
    });
    tr.find("option:contains('__empty')").prop('selected', true);
    tr.find("option:contains('__empty')").attr('selected', 'selected');
  },

  deselectEmpty(tr) {
    tr.find('option:selected').each((index, item) => {
      $(item).prop('selected', false);
    });
  },

  deselectCheckbox(tr) {
    const emptyOption = tr.find("option:contains('__empty')");
    if (emptyOption.attr('selected')) {
      emptyOption.prop('selected', false);
      emptyOption.removeAttr('selected');
    }

    if (tr.find('input').is(':checked')) {
      tr.find('input').prop('checked', false);
      tr.find('input').removeAttr('checked');
    }
  },
};
